import React from "react";

export default function FaqCard({ parent, index, fun, cstate }) {
  // const [state1, setStatge1] = React.useState(10);
  // const [state2, setStatge2] = React.useState(10);
  // setStates({ ["state" + 0]: true });
  // console.log(states["state" + index]);
  // console.log(`${"state" + index}` == false ? "radi" : "ne radi");

  return (
    <div
      className={
        cstate["state" + index] == 10
          ? "col-xl-3 col-lg-3 col-md-12 col-sm-12 mr-3  faq-card"
          : "col-xl-3 col-lg-3 col-md-12 col-sm-12 mr-3  faq-card active"
      }
    >
      <div className="d-flex justify-content-center faq-card--heading">
        <img
          className=""
          src={parent.img}
          alt="chatbot implementation, nlp chatbot, deep learning, weaver chatbot"
        />
        <h2 className="">{parent.title}</h2>
      </div>
      <div className="faq-card--content">
        {parent.content.map((item, i) => (
          <div className="faq-card--content--item" key={i}>
            <div
              className="row faq-card--content--item--heading"
              onClick={() => fun(index, i)}
            >
              <i
                className={
                  cstate["state" + index] == i
                    ? "col-2 fas fa-minus plus"
                    : "col-2 fas fa-plus plus"
                }
              />
              <p
                className={
                  cstate["state" + index] == i
                    ? "col-10  faq-card--content--item--heading--title active"
                    : "col-10  faq-card--content--item--heading--title"
                }
              >
                {item.title}
              </p>
            </div>

            <div
              className={
                cstate["state" + index] == i
                  ? "faq-card--content--item--text active"
                  : "faq-card--content--item--text"
              }
            >
              <p dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
