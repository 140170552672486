/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ScrollContext as ScrollNav } from "react-router-scroll-4";

import Loader from "./components/Loader";
import Alert from "./components/Alert";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const Home = lazy(() => import("./pages/home"));
const Privacy = lazy(() => import('./pages/privacy'));

const Finance = lazy(() => import("./pages/finance"));
const Chatbot = lazy(() => import("./pages/chatbot"));
import Contact from "./pages/contact";
const Insurance = lazy(() => import("./pages/insurance"));
const Telco = lazy(() => import("./pages/telco"));
import Page404 from "./components/Page404";
import Edit from "./pages/blog/Edit";
import Article from "./pages/blog/Article";
import Faq from "./pages/faq";
import OtherIndustries from "./pages/other-industries";
import HowItWorks from "./pages/how-it-works";

import Login from "./pages/blog/Login";
const Blog = lazy(() => import("./pages/blog/"));
import AddArticle from "./pages/blog/AddArticle";
import RequestDemo from "./components/RequestDemo";
import TotalniRazjeb from "./components/TotalniRazjeb";

const AlertContext = React.createContext();
const ClickContext = React.createContext();

const App = () => {
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertState, setAlertState] = React.useState("");
  const [isClickedMenu, setIsClickedMenu] = React.useState(false);

  const ScrollToDownload = () => {
    refDownload.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      alignToTop: true,
      inline: "nearest"
    });
  };
  const refDownload = React.useRef(null);

  const [visible, setVisible] = React.useState(false);

  const showForm = () => {
    setVisible(true);
  };

  const hideForm = () => {
    setVisible(false);
  };

  const ToogleMenu = () => {
    isClickedMenu == false ? setIsClickedMenu(true) : setIsClickedMenu(false);
  };

  const ResetContext = () => {
    setIsClickedMenu(false);
  };

  const setAlert = (message, state) => {
    setAlertMessage(message);
    setAlertState(state);

    setTimeout(() => {
      setAlertMessage("");
      setAlertState("");
    }, 2500);
  };

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <ScrollNav>
          <AlertContext.Provider
            value={{
              setAlert,
              hideForm,
              visible,
              ScrollToDownload,
              refDownload,
              showForm
            }}
          >
            <div id="bg-container" className="background-container">
              <ClickContext.Provider
                value={{
                  ToogleMenu,
                  isClickedMenu,
                  setIsClickedMenu,
                  ResetContext
                }}
              >
                <Navbar />
              </ClickContext.Provider>
              <RequestDemo />

              <Alert alertMessage={alertMessage} alertState={alertState} />

              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/why-ai-chatbot" component={Chatbot} />
                <Route path="/contact-weaver" component={Contact} />
                <Route
                  exact
                  path="/other-industries-chatbot"
                  component={OtherIndustries}
                />
                <Route exact path="/finance-ibanking" component={Finance} />
                <Route exact path="/insurance-chatbot" component={Insurance} />
                <Route exact path="/telecom-solution" component={Telco} />
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/privacy-policy" component={Privacy} />
                <Route
                  exact
                  path="/other-industries-chatbot"
                  component={OtherIndustries}
                />
                {/* <Route
                  path="/bc0c5fb3b12ce8a609da1182c02d0cfc"
                  component={TotalniRazjeb}
                /> */}
                <Route exact path="/how-it-works-nlp" component={HowItWorks} />
                <Route exact path="/blog/login" component={Login} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/blog/new" component={AddArticle} />
                <Route exact path="/blog/:slug" component={Article} />
                <Route exact path="/blog/edit/:slug" component={Edit} />
                <Route exact path="/404" component={Page404} />
                <Route component={Page404} />
              </Switch>

              <Footer />
            </div>
          </AlertContext.Provider>
        </ScrollNav>
      </Suspense>
    </Router>
  );
};

if (document.getElementById("app")) {
  ReactDOM.render(<App />, document.getElementById("app"));
}

export { AlertContext, ClickContext };
