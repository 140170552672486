import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { isAuthenticated, fetchCookie } from "../../Helpers";
import MediaQuery from "react-responsive";
import Moment from "react-moment";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
// import {
//   FacebookShareButton,
//   LinkedinShareButton,
//   TwitterShareButton
// } from "react-share";

import { Helmet } from "react-helmet";
const readingTime = require("reading-time");

export default function Article({ match }) {
  // console.log(match.params.slug);
  const [article, setArticle] = React.useState({});
  const [style, setStyle] = React.useState({});
  const [stats, setStats] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [btn, setBtn] = React.useState(false);
  const isIeGono = false || !!document.documentMode;
  document.addEventListener("DOMContentLoaded", function() {
    window.scrollTo(0, 0);
  });
  React.useEffect(() => {
    if (match.params.slug && match.params.slug !== "new") {
      axios
        .get(`/api/article/${match.params.slug}`)
        .then(res => {
          const isIe = false || !!document.documentMode;
          if (isIe == true) {
            const article = res.data;
            const nth = function(d) {
              if (d > 3 && d < 21) return "th";
              switch (d % 10) {
                case 1:
                  return d + "st";
                case 2:
                  return d + "nd";
                case 3:
                  return d + "rd";
                default:
                  return d + "th";
              }
            };
            const months = [
              "nullReference",
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December"
            ];
            var f = article.created_at.split(" ");
            var left = f[0].split("-");
            var time = readingTime(article.content);
            const day = left[2].replace(/^0+/, "");
            const month = left[1].replace(/^0+/, "");
            const x = ` <div class="single-post-container container">
        
						<div style="background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
						url(/img/blog/${
              article.slug
            }-min.png); background-size: cover!important;" class="single-post-container--heading">
							<div class="single-post-container--heading--text">
								<h1>${article.title}</h1>
								<p>${article.caption}</p>
							</div>
							<div style="display: flex; justify-content: center;" class="single-post-container--heading--time">
								<img style="width: 2rem; height: 2rem;" src="/img/components/stopwatch-svg.png" alt="stopwatch" />
		
								<p style="margin-top: 5px; margin-left: 10px;">${time.text}</p>
							</div>
						</div>
						<div class="single-post-container--body">
							<div class="single-post-container--body--created">
								<p>
									${months[month] + " " + nth(day) + ", " + left[0]}
								</p>
							</div>
							<div
								class="single-post-container--body--text"
							 
							>${article.content}</div>
							<span />
							<div style="display: flex;" class="single-post-container--body--author">
								<img src="/img/weaver-logo.png" alt="Weaver Team" />
								<div class="single-post-container--body--author--text">
									<p style="margin-top: 1.2rem">Weaver AI Team</p>
								</div>
							</div>
							<div class="single-post-container--body--share-modal">
							<img
								onClick="document.getElementById('togleMe').classList.toggle('toggled');"
								src="/img/components/share.png"
								alt="TEST"
							/>
							<div
								class="single-post-container--body--share-modal--social">
								<div style="padding-top: 10px;" id="togleMe"
								class="single-post-container--body--share-modal--social--icons"
								>
								<a  href="//www.facebook.com/sharer/sharer.php?u=weaverbot.ai/blog/${
                  article.slug
                }" target="_blank">
										<p><i class="fab fa-facebook-f" ></i></p>
								</a>
					<a href="https://www.linkedin.com/shareArticle/?url=www.weaverbot.ai/blog/${
            article.slug
          }" target="_blank">
						<p><i class="fab fa-linkedin-in"></i></p>
					</a>
					<a href="https://twitter.com/intent/tweet?text=www.weaverbot.ai/blog/${
            article.slug
          }" target="_blank">
					<p><i class="fab fa-twitter"></i></p>
					</a>

								</div>
							</div>
						</div>
					</div>
				</div>`;

            setTimeout(() => {
              var f = article.created_at.split(" ");
              var left = f[0].split("-");

              var right = f[1].split(":");

              document.getElementById("ie-render-single").innerHTML = x;
            }, 1000);
          } else {
            setArticle(res.data);
            var x = readingTime(res.data.content);
            setStats(x);
            setStyle({
              background:
                "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/img/blog/" +
                res.data.slug +
                "-min.png) no-repeat",
              backgroundSize: "cover"
            });
          }
        })
        .catch(err => {
          console.log(err);
          if (err.response.status === 404) {
            window.location.href = "/404";
          }
        });
    }
  }, []);

  const deleteArticle = () => {
    if (confirm("Are you sure you want to delete this article?")) {
      axios
        .delete(`/api/article/${match.params.slug}`, {
          headers: {
            Authorization: fetchCookie("x-auth")
          }
        })
        .then(() => (window.location.href = "/blog"));
    }
  };
  // React.useEffect(() => {
  //   document.body.addEventListener("click", toggleShare2);
  // }, [toggleShare2]);

  const toggleShare = e => {
    if (!open) {
      setOpen(true);

      document.body.addEventListener("click", toggleShare2);
    } else {
      setOpen(false);
      document.body.removeEventListener("click", toggleShare2);
    }
  };

  const toggleShare2 = () => {
    setOpen(false);
  };

  return isIeGono == false || Object.keys(article).length ? (
    <>
      <Helmet>
        <title>{article.title}</title>
        <meta name="keyword" content="ibanking" />
        <meta
          name="information"
          content="chatbots in ibanking, chatbots in financial services, conversational banking, intelligent banking, chatbots for banking app, ibanking chatbot, banking ai, chatbots for credit unions, credit unions ai"
        />
      </Helmet>
      <div className="single-post-container container">
        {isAuthenticated() ? (
          <div className="blog-post__top-buttons">
            <button className="button" onClick={deleteArticle}>
              Delete Article
            </button>
            <Link to={`/blog/edit/${article.slug}`}>
              <button className="button">Edit</button>
            </Link>
          </div>
        ) : null}
        <div style={style} className="single-post-container--heading">
          <div className="single-post-container--heading--text">
            <h1>{article.title}</h1>
            <p>{article.caption}</p>
          </div>
          <div className="single-post-container--heading--time">
            <img src="/img/components/stopwatch-svg.png" alt="stopwatch" />

            <p>{stats.text}</p>
          </div>
        </div>
        <div className="single-post-container--body">
          <div className="single-post-container--body--created">
            <p>
              <Moment format="MMMM Do, YYYY">{article.created_at}</Moment>
            </p>
          </div>
          <div
            className="single-post-container--body--text"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
          <span />
          <div className="single-post-container--body--author">
            <img src="/img/weaver-logo.png" alt="Weaver Team" />
            <div className="single-post-container--body--author--text">
              <p>Weaver AI Team</p>
              {/* <p>Uloga</p> */}
            </div>
          </div>
          <div className="single-post-container--body--share-modal">
            <img
              onClick={e => toggleShare(e)}
              src="/img/components/share.png"
              alt="TEST"
            />
            <div
              className={
                open == false
                  ? "single-post-container--body--share-modal--social"
                  : "single-post-container--body--share-modal--social toggled"
              }
            >
              <div
                className={
                  open == false
                    ? "single-post-container--body--share-modal--social--icons"
                    : "single-post-container--body--share-modal--social--icons toggled"
                }
              >
                <FacebookShareButton
                  url={`https://weaverbot.ai.com/blog/${article.slug}`}
                  quote={article.title}
                >
                  <i className="fab fa-facebook-f" />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={`https://weaverbot.ai/blog/${article.slug}`}
                  quote={article.title}
                >
                  <i className="fab fa-linkedin-in" />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={`https://weaverbot.ai/blog/${article.slug}`}
                  quote={article.title}
                >
                  <i className="fab fa-twitter" />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div id="ie-render-single" />
  );
}
