import React from "react";
import { AlertContext } from "../../app";
import { validateHeroForm } from "../../Helpers";
import axios from "axios";

export default function LearMore() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [active, setActive] = React.useState(0);
  const { setAlert, refDownload } = React.useContext(AlertContext);
  const openForm = () => {
    setVisible(true);
    setCount(1);
  };

  const handleSubmit = e => {
    if (count === 1) {
      e.preventDefault();

      const validate = validateHeroForm(name, email);

      if (validate === true) {
        axios
          .post("/api/heroForm", { name, email, company })
          .then(res => {
            if (res.status === 200) {
              setName("");
              setEmail("");
              setCompany("");
              setVisible(false);
              setAlert("Your request has been sent successfully!", "success");
              setCount(0);
              const link = document.createElement("a");
              link.href = res.data;
              link.setAttribute(
                "download",
                "Whitepaper - Weaver The Future of Chatbots"
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            }
          })
          .catch(err => setAlert(err, "danger"));
      } else {
        setAlert(validate, "danger");
      }
    }
  };
  return (
    <div ref={refDownload} className="learn-more">
      <div className="container">
        <div className="learn-more__content">
          <h3>Want to learn more?</h3>
          <div className="learn-more__content--buttons">
            <div className="form-container-div">
              <div className="benefits--button bold">
                <a
                  href="/pdf/light.pdf"
                  download="Weaver LightPaper - The Future of Chatbots"
                >
                  DOWNLOAD LIGHTPAPER
                </a>
              </div>
              <div
                className={
                  visible === true
                    ? "download-form download-form--active"
                    : "download-form"
                }
              >
                <form method="POST">
                  <section className="row">
                    <section className="col-lg-4 col-sm-12 download-form__input">
                      <input
                        className={active === 1 ? "active" : ""}
                        onFocus={() => setActive(1)}
                        onBlur={() => setActive(0)}
                        id="name"
                        onChange={e => setName(e.target.value)}
                        type="text"
                        placeholder="First name, last name*"
                        value={name}
                      />
                    </section>
                    <section className="col-lg-4 col-sm-12 download-form__input">
                      <input
                        className={active === 2 ? "active" : ""}
                        onFocus={() => setActive(2)}
                        onBlur={() => setActive(0)}
                        id="email"
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email*"
                        value={email}
                      />
                    </section>
                    <section className="col-lg-4 col-sm-12 download-form__input">
                      <input
                        className={active === 3 ? "active" : ""}
                        onFocus={() => setActive(3)}
                        onBlur={() => setActive(0)}
                        id="company"
                        onChange={e => setCompany(e.target.value)}
                        type="text"
                        placeholder="Company"
                        value={company}
                      />
                    </section>
                  </section>
                </form>
              </div>
              <div
                onClick={e => {
                  openForm();
                  handleSubmit(e);
                }}
                className="btn-yellow btn-yellow--padding btn-yellow--padding--higher-width mt-4"
              >
                <p>GET WHITEPAPER</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
