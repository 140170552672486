import React, { lazy } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { isAuthenticated, fetchCookie, validateArticle } from "../../Helpers";
import { AlertContext } from "../../app";
import Page404 from "../404";
import CKEditor from "ckeditor4-react";

export default function Edit({ match }) {
  const [article, setArticle] = React.useState({});
  const [originalTitle, setOriginalTitle] = React.useState("");
  const [originalSlug, setOriginalSlug] = React.useState("");
  const refFile = React.useRef(null);
  const { setAlert } = React.useContext(AlertContext);
  const resetAlert = () => {
    setTimeout(() => {
      setAlert("");
    }, 2500);
  };

  const setImg = param => {
    setArticle({ ...article, img: param });
  };

  const extract64 = () => {
    if (
      typeof refFile.current.files[0] !== "undefined" &&
      refFile.current.files[0].type === "image/png"
    ) {
      let file = refFile.current.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        let result = reader.result;
        setImg(result);
        setTimeout(() => {
          setImg(result);
        }, 1000);
      };
      reader.onerror = function(error) {
        console.log(error);
      };
    } else {
      setAlert("Image isn't in .png format! Please try again", "danger");
    }
  };
  // console.log(match.params.slug);

  React.useEffect(() => {
    const slug = match.params.slug;

    axios
      .get(`/api/article/edit/${slug}`, {
        headers: {
          Authorization: fetchCookie("x-auth")
        }
      })
      .then(res => {
        console.log(res);

        setArticle(res.data);
        setOriginalTitle(res.data.title);
        setOriginalSlug(slug);
      })
      .catch();
  }, []);

  const updateArticle = () => {
    if (
      typeof refFile.current.files[0] !== "undefined" &&
      refFile.current.files[0].type != "image/png"
    ) {
      setAlert("Image isn't in .png format! Please try again", "danger");
    } else {
      if (typeof article.img === "undefined") {
        axios
          .put(`/api/article/${originalSlug}`, article, {
            headers: {
              Authorization: fetchCookie("x-auth")
            }
          })
          .then(() => {
            setAlert(
              "Article updated successfully! You will soon be redirected back to the article...",
              "success"
            );

            setTimeout(() => {
              window.location.href = `/blog/${article.slug}`;
            }, 2500);
          })
          .catch(err => setAlert(err.response.data.messages[0], "error"));
      } else {
        setTimeout(() => {
          axios
            .put(`/api/article/${originalSlug}`, article, {
              headers: {
                Authorization: fetchCookie("x-auth")
              }
            })
            .then(() => {
              setAlert(
                "Article updated successfully! You will soon be redirected back to the article...",
                "success"
              );

              setTimeout(() => {
                window.location.href = `/blog/${article.slug}`;
              }, 2500);
            })
            .catch(err => setAlert(err.response.data.messages[0], "error"));
        }, 500);
      }
    }
  };

  return Object.keys(article).length && isAuthenticated() ? (
    <section className="insert-page">
      <section className="insert-page--form container">
        <Link to={`/blog/${article.slug}`}>
          <button className="button btn-big">Go Back</button>
        </Link>
        <h1 className="h1-small">You are editing: {originalTitle}</h1>
        <form
          className="insert-page--form container"
          encType="multipart/form-data"
          onSubmit={e => {
            e.preventDefault();
            updateArticle();
          }}
        >
          <input
            onChange={e => setArticle({ ...article, title: e.target.value })}
            placeholder="Title"
            value={article.title}
          />
          <input
            onChange={e => setArticle({ ...article, caption: e.target.value })}
            placeholder="Caption"
            value={article.caption}
          />
          <input
            onChange={e =>
              setArticle({ ...article, seo_keywords: e.target.value })
            }
            placeholder="Seo Keywords"
            value={article.seo_keywords}
          />
          <div className="insert-page--form--slug">
            <div className="insert-page--form--slug--text">{`/blog/`}</div>
            <input
              onChange={e => setArticle({ ...article, slug: e.target.value })}
              placeholder="Article URL (automatically starts with /blog/)"
              value={article.slug}
            />
          </div>
          <CKEditor
            data={article.content}
            onChange={event => {
              setArticle({ ...article, content: event.editor.getData() });
            }}
            config={{
              filebrowserUploadUrl: "/api/upload",
              extraPlugins: "justify,showblocks,embed",
              embed_provider:
                "https://ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}"
            }}
          />
          <input type="file" onChange={extract64} ref={refFile} />
          <input className="button" type="submit" value="Update" />
        </form>
      </section>
    </section>
  ) : (
    <Page404 />
  );
}
