import React from "react";

export default function WhyWeaver({ data }) {
  return (
    <div className="finance-hero">
      <div className="finance-hero--gradient">
        <div className="row finance-hero--gradient--grid container">
          {data.map((el, i) => (
            <div
              className="col-lg-4 finance-hero--gradient--grid--item"
              key={i}
            >
              <p className="orange">{el.percent}</p>
              <p dangerouslySetInnerHTML={{ __html: el.perDesc }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
