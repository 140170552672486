import React, { lazy } from "react";
const HeroForm = lazy(() => import('../../components/HeroForm'));
import Process from "./Process";
import Architecture from "./Architecture";
import Analytics from "./Analytics";
import LearnMore from "./LearMore";
import HowForm from "./HowForm";
import { Helmet } from "react-helmet";

export default function HowItWorks() {
  const [heroData] = React.useState({
    title: "How It Works",
    description:
      "AI technology is fickle thing. Fortunately, we have the knowledge and power to make it work outstandingly. Take a peek into Weaver’s brain and see how it works.",
    cls: "how-hero"
  });
  return (
    <>
      <Helmet>
        <title>How It Works</title>
        <meta name="keyword" content="nlp chatbot" />
        <meta
          name="information"
          content="nlp, virtual assistant, ai virtual assistant, deep learning, machine learning, deep integration, omnichannel, multichannel, intelligent talk, conversational ai, chatbot implementation"
        />
      </Helmet>
      <HeroForm data={heroData} />
      <Process />
      <Architecture />
      <Analytics />
      <LearnMore />
      <HowForm />
    </>
  );
}
