import React from "react";
import MediaQuery from "react-responsive";

export default function Analytics() {
  const [active, setActive] = React.useState(false);
  return (
    <>
      <MediaQuery minWidth={770}>
        <div className="analytics">
          <section className="analytics__header">
            <h2>Analytics in real time</h2>
            <section className="analytics__grid__image">
              <img src="/img/components/tablet-mob.png" alt="nlp, virtual assistant, deep learning, machine learning, deep integration, omnichannel, intelligent talk" />
            </section>
          </section>
          <section className="analytics__grid container">
            <section className="analytics__grid__text">
              <p>
                We know our clients and wish to meet their needs. With Weaver, all
                information gathered through conversation has never been easier to
                process. We are proud of our deliverable processes, which are all
                centralized in one backend core, making Weaver an AI assistant with
                a one-of-a-kind interface for all of our financial clients.
          </p>
              <p>
                What’s more, Weaver can be implemented across all communication
                channels. Conversations are stored in a single repository so that
                the processing, analysis and reporting can be carried out through
                this one integrated location, regardless of the channel of
                communication.
          </p>
              <p>
                Analytics are available to administrators 24/7 and are part of the
                web application. The reports are divided into 10 segments, but
                admins are able to see a short summary of the statistics on the
                front page. Admins are also provided with an overview of the
                conversation history, as well as user data to generate an overall
                impression of their clients.
          </p>
            </section>
          </section>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={769}>
        <div className="analytics">
          <section className="analytics__header">
            <h2>Analytics in real time</h2>
            <section className="analytics__grid container">
              <section className="analytics__grid__text">
                <p>
                  We know our clients and wish to meet their needs. With Weaver, all
                  information gathered through conversation has never been easier to
                  process.
          </p>
                <p className={active === true ? 'linq' : 'linq active'} onClick={() => setActive(true)}>Read more ></p>
                <div className={active === true ? 'holder' : 'holder active'}>
                  <p>
                    We are proud of our deliverable processes, which are all
                        centralized in one backend core, making Weaver an AI assistant with
                        a one-of-a-kind interface for all of our financial clients.  What’s more, Weaver can be implemented across all communication
                        channels. Conversations are stored in a single repository so that
                        the processing, analysis and reporting can be carried out through
                        this one integrated location, regardless of the channel of
                        communication.
          </p>
                  <p>
                    Analytics are available to administrators 24/7 and are part of the
                    web application. The reports are divided into 10 segments, but
                    admins are able to see a short summary of the statistics on the
                    front page. Admins are also provided with an overview of the
                    conversation history, as well as user data to generate an overall
                    impression of their clients.
          </p>
                </div>
              </section>
            </section>
            <section className="analytics__grid__image">
              <img src="/img/components/tablet-mob.png" alt="nlp, virtual assistant, deep learning, machine learning, deep integration, omnichannel, intelligent talk" />
            </section>
          </section>

        </div>
      </MediaQuery>
    </>
  );
}
