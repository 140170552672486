import React from "react";
import { Link } from "react-router-dom";
import RequsetDemo from "../../components/RequestDemo";
import { AlertContext } from "../../app";

export default function Robot() {
  const { showForm } = React.useContext(AlertContext);
  return (
    <section className="robot container">
      <div className="robot--img">
        <img src="img/components/robots/robot-bottom.webp" alt="Robot" />
      </div>
      <div onClick={showForm} className="btn-yellow">
        REQUEST A DEMO
      </div>
    </section>
  );
}
