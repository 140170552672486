import React from "react";
import { Link } from "react-router-dom";
import { ClickContext, AlertContext } from "../app";
import RequsetDemo from "../components/RequestDemo";

export default function Navbar() {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const { ToogleMenu, isClickedMenu, ResetContext } = React.useContext(
    ClickContext
  );
  const [clickRes, setClickRes] = React.useState(false);
  const [clickRes2, setClickRes2] = React.useState(false);
  const ddl = () => {
    if (clickRes == true) {
      setClickRes(false);
    } else {
      setClickRes(true);
    }
  };
  const ddl2 = () => {
    if (clickRes2 == true) {
      setClickRes2(false);
    } else {
      setClickRes2(true);
    }
  };
  const { showForm } = React.useContext(AlertContext);

  document.addEventListener("scroll", () => {
    const isTop = window.pageYOffset > 80;
    if (isTop !== isScrolled) {
      setIsScrolled(isTop);
    }
  });

  return (
    <section className={isScrolled === false ? "navbarr" : "navbarr bg-white"}>
      <div className="container">
        <div className="navbarr__brand">
          <Link onClick={ResetContext} to="/">
            <img
              alt="Weaver-navbarr-logo"
              src={
                isScrolled === false
                  ? "/img/logo-how.png"
                  : "/img/weaver-logo.png"
              }
            />
          </Link>
        </div>
        {/* <div className={isScrolled === true ? "navbarr__brand" : "d-none"}>
          <Link onClick={ResetContext} to="/">
            <img alt="Weaver-navbarr-logo" src="/img/logo-2.png" />
          </Link>
        </div> */}
        <div id="nav-icon3">
          <span />
          <span />
          <span />
          <span />
        </div>
        <a
          onClick={ToogleMenu}
          className={
            isClickedMenu == false ? "target-burger" : "target-burger toggled"
          }
        >
          <ul className="buns">
            <li className={isScrolled === false ? "bun" : "bun bg-blue"} />
            <li className={isScrolled === false ? "bun" : "bun bg-blue"} />
          </ul>
        </a>
        <section
          className={
            isClickedMenu == false ? "navbarr__links" : "navbarr__links toggle"
          }
        >
          <Link to="/why-ai-chatbot" className="dropdown-container--link">
            Why Weaver
          </Link>
          <div className="dropdown-container">
            <div className="dropdown-container--main">
              Solutions <i className="fas fa-caret-down" />
            </div>
            <div className="dropdown-container--content-service">
              <ul>
                <li>
                  <Link to="/finance-ibanking">Finance</Link>
                </li>
                <li>
                  <Link to="/insurance-chatbot"> Insurance</Link>
                </li>
                <li>
                  <Link to="/telecom-solution">Telecommunications</Link>
                </li>
                <li>
                  <Link to="/other-industries-chatbot">other industries</Link>
                </li>
              </ul>
            </div>
          </div>
          <Link to="/blog">Blog</Link>
          <Link to="/how-it-works-nlp">How it works</Link>
          <div className="dropdown-container">
            <div className="dropdown-container--main">
              Selecta <i className="fas fa-caret-down" />
            </div>
            <div className="dropdown-container--content-service">
              <ul>
                <li>
                  <a href="https://www.selectacrm.app" target="_blank">
                    Meet our relationship platform
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <Link to="/contact-weaver">contact</Link>
          <div className="contact-circle">
            <p onClick={showForm}>request demo</p>
          </div>
        </section>
        <section
          className={
            isClickedMenu == false
              ? "navbarr__links responsive"
              : "navbarr__links responsive toggle"
          }
        >
          <div className="navbarr__links--res-item">
            <Link
              onClick={ToogleMenu}
              to="/why-ai-chatbot"
              className="dropdown-container--link"
            >
              Why weaver
            </Link>
            <div className="line-gradient" />
          </div>
          <div className="navbarr__links--res-item">
            <p onClick={ddl}>
              Solutions <i className="fas fa-caret-down" />
            </p>
            <div className={clickRes === true ? "dd-res active" : "dd-res"}>
              <Link onClick={ToogleMenu} to="/insurance-chatbot">
                Insurance
              </Link>
              <Link onClick={ToogleMenu} to="/finance-ibanking">
                Finance
              </Link>
              <Link onClick={ToogleMenu} to="/telecom-solution">
                Telecommunications
              </Link>
              <Link onClick={ToogleMenu} to="/other-industries-chatbot">
                Other industries
              </Link>
            </div>
            <div className="line-gradient" />
          </div>
          <div className="navbarr__links--res-item">
            <Link onClick={ToogleMenu} to="/blog">
              Blog
            </Link>

            <div className="line-gradient" />
          </div>
          <div className="navbarr__links--res-item">
            <Link onClick={ToogleMenu} to="/how-it-works-nlp">
              How it works
            </Link>

            <div className="line-gradient" />
          </div>
          <div className="navbarr__links--res-item">
            <p onClick={ddl2}>
              Selecta <i className="fas fa-caret-down" />
            </p>
            <div className={clickRes2 === true ? "dd-res active" : "dd-res"}>
              <a href="www.selectacrm.app" target="_blank">
                Meet our relationship platform
              </a>
            </div>
            <div className="line-gradient" />
          </div>
          <div className="navbarr__links--res-item">
            <Link onClick={ToogleMenu} to="/contact-weaver">
              contact
            </Link>
            <div className="line-gradient" />
          </div>
          <div className="navbarr__links--res-item">
            <p onClick={showForm}>request demo</p>
            <div className="line-gradient" />
          </div>
        </section>
      </div>
    </section>
  );
}
