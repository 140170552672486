import React from "react";
import { otherIndustries } from "../../Helpers";
import MediaQuery from "react-responsive";

export default function VideoGrid() {
  const refvideos = [];
  const [position, setPosition] = React.useState(-1);
  const [enabled, setEnabled] = React.useState(false);
  React.useEffect(() => {
    const radi = document.fullscreenEnabled;
    if (radi != null || radi == true) {
      setEnabled(true);
    }
  }, []);
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  // const radi = document.fullscreenEnabled;
  const format = isChrome == true ? ".mp4" : ".webm";

  const clickMe = (parent, child) => {
    if (states["state" + parent] == child) {
      setStates({ ...states, ["state" + parent]: 10 });
    } else {
      setStates({ ...states, ["state" + parent]: child });
    }
  };

  const [states, setStates] = React.useState({
    state0: 10,
    state1: 10,
    state2: 10
  });
  // var userAgent = window.navigator.userAgent;
  // var isSafari =
  //   /constructor/i.test(window.HTMLElement) ||
  //   (function(p) {
  //     return p.toString() === "[object SafariRemoteNotification]";
  //   })(
  //     !window["safari"] ||
  //       (typeof safari !== "undefined" && safari.pushNotification)
  //   );
  document.addEventListener("fullscreenchange", event => {
    if (!document.fullscreenElement) {
      setPosition(-1);
      // for (var i = 0; i < 3; i++) {
      //   var video = document.getElementById(`item-${i}`);
      //   video.currentTime = -1;
      // }
    }
  });
  const fullscreen = param => {
    setPosition(param);
    const elem = refvideos[param];
    /* Interact with it as a normal DOM element: */

    const myVideo = document.getElementById(`item-${param}`);
    elem.requestFullscreen();
    elem.disablePictureInPicture = true;
    elem.play();
  };
  return (
    <div className="other-grid">
      {otherIndustries.map((item, i) => (
        <div
          key={i}
          className={
            i % 2 == 0
              ? "other-grid--item to-left"
              : "other-grid--item to-right"
          }
        >
          <div className="other-grid--container container">
            <div className="other-grid--container--video-section">
              <h2>{item.title}</h2>
              <h3>{item.subtitle}</h3>
            </div>
            <div className="other-grid--container--section">
              <div className="row">
                <div className="col-lg-6 col-md-12 pl-0">
                  <div className="col-12">
                    <div
                      className={
                        enabled == false
                          ? "other-grid--container--section--video--ios"
                          : "other-grid--container--section--video"
                      }
                    >
                      {enabled == false ? (
                        <video
                          ref={input => {
                            refvideos[i] = input;
                          }}
                          poster={item.img}
                          id={`item-${i}`}
                          className="video-ios"
                          autoPlay={false}
                          controls="true"
                          muted
                          preload="false"
                          class
                        >
                          <source src={item.video + ".mp4"} type="video/mp4" />
                        </video>
                      ) : (
                        <>
                          <video
                            ref={input => {
                              refvideos[i] = input;
                            }}
                            id={`item-${i}`}
                            className={position === i ? "active" : ""}
                            autoPlay={false}
                            controls="true"
                            muted
                            preload="false"
                            class
                          >
                            <source
                              src={item.video + ".mp4"}
                              type="video/mp4"
                            />
                          </video>
                          <img
                            src={item.img}
                            alt="retail chatbot, healthcare chatbot, e-commerce ai, automotive ai, hospitality chatbot, virtual assistant, ai virtual assistant"
                            onClick={() => fullscreen(i)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="col-12">
                    <MediaQuery minWidth={770}>
                      <div className="other-grid--container--section--main">
                        <p>{item.content[0].text}</p>
                      </div>
                    </MediaQuery>
                  </div>
                </div>
              </div>

              <MediaQuery minWidth={770}>
                <div className="row mt-3">
                  <div className="col-6 pl-0">
                    <div className="col-12">
                      <div className="other-grid--container--section--item">
                        <div className="other-grid--container--section--item--title">
                          <p>{item.content[1].title}</p>
                        </div>
                        <div>
                          <p>{item.content[1].text}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="col-12">
                      <div className="other-grid--container--section--item">
                        <div className="other-grid--container--section--item--title">
                          <p>{item.content[2].title}</p>
                        </div>
                        <div>
                          <p>{item.content[2].text}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={769}>
                <div className="other-grid--container--section--res">
                  <div className="row other-grid--container--section--res--nav">
                    <div
                      className="col-6 other-grid--container--section--res--nav--item"
                      onClick={() => clickMe(i, 0)}
                    >
                      <p>
                        {" "}
                        <span
                          className={states["state" + i] == 0 ? "active" : ""}
                        >
                          {item.content[1].title.replace(":", "")}
                        </span>{" "}
                        <i
                          className={
                            states["state" + i] == 0
                              ? "fas fa-minus plus"
                              : "fas fa-plus plus"
                          }
                        />
                      </p>
                    </div>
                    <div
                      className="col-6 other-grid--container--section--res--nav--item"
                      onClick={() => clickMe(i, 1)}
                    >
                      <p>
                        <span
                          className={states["state" + i] == 1 ? "active" : ""}
                        >
                          {item.content[2].title.replace(":", "")}
                        </span>
                        <i
                          className={
                            states["state" + i] == 1
                              ? "fas fa-minus plus"
                              : "fas fa-plus plus"
                          }
                        />
                      </p>
                    </div>
                  </div>
                  <div className="other-grid--container--section--res--content">
                    <div
                      className={item.content[2].title.replace(":", "")}
                      className={
                        states["state" + i] == 0
                          ? "other-grid--container--section--res--content--item active"
                          : "other-grid--container--section--res--content--item"
                      }
                    >
                      <p>{item.content[1].text}</p>
                    </div>
                    <div
                      className={
                        states["state" + i] == 1
                          ? "other-grid--container--section--res--content--item active"
                          : "other-grid--container--section--res--content--item"
                      }
                    >
                      <p>{item.content[2].text}</p>
                    </div>
                  </div>
                </div>
              </MediaQuery>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
