import React from "react";
import { requestValidation, APIKEY } from "../../Helpers";
import { AlertContext } from "../../app";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

export default function HowForm() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [check, setCheck] = React.useState(false);
  const { setAlert, refDownload } = React.useContext(AlertContext);
  const [bot, setBot] = React.useState(false);

  const handleChange = value => {
    // console.log("Captcha value:", value);
    // if value is null recaptcha expired
    if (value != null) setBot(true);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const validation = requestValidation(name, email, message, check, bot);

    if (validation === true) {
      axios
        .post("api/contactEmail", {
          name,
          email,
          company,
          message
        })
        .then(res => {
          if (res.status === 200) {
            setName("");
            setEmail("");
            setCompany("");
            setMessage("");
            setCheck(false);
            setAlert(res.data, "success");
          }
        })
        .catch(err => setAlert(err, "danger"));
    } else {
      setAlert(validation, "danger");
    }
  };
  return (
    <div className="row how-form container">
      <MediaQuery minWidth={993}>
        <section className="col-lg-5 m-auto how-form__image">
          <img src="/img/weaver-logo-how.png" />
        </section>
      </MediaQuery>
      <section className="col-lg-6 offset-lg-1 how-form__form">
        <MediaQuery minWidth={993}>
          <p>
            Discover how Weaver can help your organisation apply the power of
            advanced technology.
          </p>
        </MediaQuery>
        <MediaQuery maxWidth={993}>
          <p>
            Got your questions ready? Ask Weaver anything ny truing out our
            demo.
          </p>
        </MediaQuery>
        <section className="">
          <form method="POST" onSubmit={handleSubmit}>
            <section className="request-demo__form">
              <div className="request-demo__form--input">
                <input
                  id="name"
                  onChange={e => setName(e.target.value)}
                  type="text"
                  placeholder="First name, last name*"
                  value={name}
                />
              </div>
              <div className="request-demo__form--input">
                <input
                  id="email"
                  onChange={e => setEmail(e.target.value)}
                  type="email"
                  placeholder="E-mail*"
                  value={email}
                />
              </div>
            </section>
            <div className="request-demo__form--input">
              <input
                id="company"
                onChange={e => setCompany(e.target.value)}
                type="text"
                placeholder="Company"
                value={company}
              />
            </div>

            <div className="request-demo__form--input">
              <textarea
                id="message"
                onChange={e => setMessage(e.target.value)}
                placeholder="Your message*"
                value={message}
                rows="6"
              />
            </div>

            <div className="request-demo__form--checkbox">
              <input
                id="check"
                onChange={() => setCheck(!check)}
                type="checkbox"
                checked={check}
              />
              <label>
                By submitting your details, you are agreeing to receive
                communications about Weaver resources, events, products, or
                services, and acknowledge that you have read our{" "}
                <Link to="/privacy-policy">privacy policy</Link> and agreed to
                submitting your information.
              </label>
            </div>
            <ReCAPTCHA
              className="captcha"
              sitekey={APIKEY}
              onChange={param => handleChange(param)}
            />
            <div className="request-demo__send-button">
              <input type="submit" value="SUBMIT" />
            </div>
          </form>
        </section>
      </section>
    </div>
  );
}
