import React from "react";
import { faq } from "../../Helpers";
import FaqCard from "./FaqCard";
import { Helmet } from "react-helmet";

export default function Faq() {
  React.useEffect(() => {
    var x = document.getElementById("bg-container");
    x.style.backgroundPosition = "top left";
  }, []);

  const clickMe = (parent, child) => {
    if (states["state" + parent] == child) {
      setStates({ ...states, ["state" + parent]: 10 });
    } else {
      setStates({ ...states, ["state" + parent]: child });
    }
  };

  const [states, setStates] = React.useState({
    state0: 10,
    state1: 10,
    state2: 10
  });

  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions</title>
        <meta name="keyword" content="weaver platform" />
        <meta
          name="information"
          content="weaver platform, chatbot implementation, nlp, deep learning, machine learning, deep learning technology, weaver chatbot, artificial intelligence"
        />
      </Helmet>
      <div className="faq-container container">
        <div className="faq-container--text">
          <h1>Frequently asked questions</h1>
        </div>
        <div className="row m-0 faq-container--grid">
          {faq.map((parent, i) => (
            <FaqCard
              parent={parent}
              index={i}
              fun={clickMe}
              cstate={states}
              key={i}
            />
          ))}
        </div>
      </div>
    </>
  );
}
