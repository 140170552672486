import React from "react";
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div className="row container for-o-for">
      <div className="col-12">
        <img src="/img/404.png" className="mx-auto d-block mt-5" />
      </div>
      <div className="col-12 text-center">
        <Link className="btn-yellow" to="/">
          Go back
        </Link>
      </div>
    </div>
  );
}
