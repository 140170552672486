import React from "react";
import axios from "axios";
import { createCookie } from "../../Helpers";

export default function AdminLogin() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = () => {
    axios
      .post("/api/blog-admin", { username, password })
      .then(res => {
        createCookie("x-auth", res.headers["x-auth"]);
        window.location.href = "/blog";
      })
      .catch(err => {
        alert(err.response.data);
        setUsername("");
        setPassword("");
      });
  };

  return (
    <div className="login-container">
      <section className="login-container--form container">
        <h1>You are not authorized to modify this section.</h1>
        <h2>Please login:</h2>
        <form
          onSubmit={e => {
            e.preventDefault();
            login(username, password);
          }}
        >
          <input
            onChange={e => setUsername(e.target.value)}
            placeholder="Username"
            type="text"
            value={username}
          />
          <input
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
            value={password}
          />
          <input className="button" type="submit" value="Login" />
        </form>
      </section>
    </div>
  );
}
