import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AlertContext } from "../app";
import { APIKEY } from "../Helpers";
import MediaQuery from "react-responsive";
import ReCAPTCHA from "react-google-recaptcha";

export default function Footer() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const { setAlert } = React.useContext(AlertContext);
  const [active, setActive] = React.useState(0);
  const [bot, setBot] = React.useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    if (name.length) {
      if (email.length) {
        const re = /\S+@\S+\.\S+/;

        if (re.test(email)) {
          if (bot === true) {
            axios
              .post("api/footerEmail", { name, email })
              .then(res => {
                if (res.status === 200) {
                  setName("");
                  setEmail("");
                  setAlert(res.data, "success");
                }
              })
              .catch(err => setAlert(err, "danger"));
          } else {
            setAlert("Please verify that you are not an robot", "danger");
          }
        }
      } else {
        setAlert("Email is required!", "danger");
      }
    } else {
      setAlert("Name is required", "danger");
    }
  };
  return (
    <section className="footer to-left">
      <section className="container">
        <MediaQuery minWidth={769}>
          <>
            <section className="d-flex justify-content-between footer--cardds">
              <section className="cardd">
                <div className="cardd--first">
                  <p className="bold">Saga d.o.o Belgrade</p>
                  <p>A Noventiq company</p>
                </div>
                <div className="cardd--second">
                  <p className="bold">64a Zorana Djindjica Blvd.</p>
                  <p className="bold mb">11070 Belgrade | SERBIA</p>
                  <p>
                    <a
                      href="mailto:weaver@saga.rs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      weaver@saga.rs
                    </a>{" "}
                    |{" "}
                    <a
                      href="https://saga.rs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      saga.rs
                    </a>
                  </p>
                </div>
                <p className="cardd--text">Find us online</p>
                <div className="cardd--icons">
                  <div className="cardd--icons__icon">
                    <a
                      href="https://www.linkedin.com/company/weaverbot-ai/about/?viewAsMember=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </div>
                  <div className="cardd--icons__icon">
                    <a
                      href="https://www.facebook.com/WeaverPlatform/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-square" />
                    </a>
                  </div>
                  <div className="cardd--icons__icon">
                    <a
                      href="https://twitter.com/weaverbot_ai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </div>
                </div>
              </section>
              <section className="cardd">
                <div className="cardd--subscribe">
                  <p className="bold">Subscribe for more news:</p>
                  <form method="POST" onSubmit={handleSubmit}>
                    <input
                      className={active === 2 ? "active" : ""}
                      onFocus={() => setActive(2)}
                      onBlur={() => setActive(0)}
                      id="name"
                      onChange={e => setName(e.target.value)}
                      type="text"
                      placeholder="Name, surname"
                      value={name}
                    />
                    <input
                      className={active === 1 ? "active" : ""}
                      onFocus={() => setActive(1)}
                      onBlur={() => setActive(0)}
                      id="email"
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      placeholder="E-mail"
                      value={email}
                    />
                    <ReCAPTCHA sitekey={APIKEY} onChange={() => setBot(true)} />

                    <input type="submit" value="SUBSCRIBE" />
                  </form>
                </div>
              </section>
              <section className="cardd">
                <ul>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/finance-ibanking">Finance</Link>
                  </li>
                  <li>
                    <Link to="/insurance-chatbot">Insurance</Link>
                  </li>
                  <li>
                    <Link to="/telecom-solution">Telecommunications</Link>
                  </li>
                  <li>
                    <Link to="/other-industries-chatbot">Other industries</Link>
                  </li>
                  <li>
                    <Link to="/why-ai-chatbot">Why Weaver</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/how-it-works-nlp">How it Works</Link>
                  </li>
                </ul>
              </section>
            </section>

            <div className="copy">
              {/* <p className="uppercase">Our partners and distributors</p>
              <div className="copy--grid">
                <a href="http://www.wagaplatform.com/">
                  <img src="/img/nfc.png" alt="NFCSB" />
                </a>
                <a href=" https://www.nfinnova.com/">
                  <img src="/img/nfi.png" alt="NFINOVA" />
                </a>
                <a href=" http://aladdingroups.com/agency/about.php">
                  {" "}
                  <img src="/img/aladin.png" alt="ALADDIN" />
                </a>
              </div> */}
              {window.location.pathname == "/" ? (
                <p>
                  Disclaimer: The data in ‘’Numbers behind Weavers success!’’
                  were drawn from Raiffeisen bank July 2019 reports. The
                  presented conversations were taken from the period between the
                  launch of the chatbot , January 2018, and July 2018.
                </p>
              ) : null}
              <p className="kag">
                Created by{" "}
                <a href="https://www.kag.marketing">
                  KickAssGrowth Digital Marketing Agency
                </a>
              </p>
            </div>
          </>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <>
            <section className="footer--cardds">
              <section className="cardd">
                <ul>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/finance-ibanking">Finance</Link>
                  </li>
                  <li>
                    <Link to="/insurance-chatbot">Insurance</Link>
                  </li>
                  <li>
                    <Link to="/telecom-solution">Telecommunications</Link>
                  </li>
                  <li>
                    <Link to="/other-industries-chatbot">Other industries</Link>
                  </li>
                  <li>
                    <Link to="/why-ai-chatbot">Why Weaver</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/how-it-works-nlp">How it Works</Link>
                  </li>
                </ul>
              </section>
              <section className="cardd">
                <div className="cardd--subscribe">
                  <p className="bold">Subscribe for more news:</p>
                  <form method="POST" onSubmit={handleSubmit}>
                    <input
                      id="name"
                      onChange={e => setName(e.target.value)}
                      type="text"
                      placeholder="Name, surname"
                      value={name}
                    />
                    <input
                      id="email"
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      placeholder="E-mail"
                      value={email}
                    />
                    <ReCAPTCHA sitekey={APIKEY} onChange={() => setBot(true)} />

                    <input type="submit" value="SUBSCRIBE" />
                  </form>
                </div>
              </section>
              <section className="cardd">
                <div className="cardd--first">
                  <p className="bold">Saga d.o.o Belgrade</p>
                  <p>A Noventiq company</p>
                </div>
                <div className="cardd--second">
                  <p className="bold">64a Zorana Djindjica Blvd.</p>
                  <p className="bold mb">11070 Belgrade | SERBIA</p>
                  <p>
                    <a
                      href="mailto:weaver@saga.rs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      weaver@saga.rs
                    </a>{" "}
                    |{" "}
                    <a
                      href="https://saga.rs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b>saga.rs</b>
                    </a>
                  </p>
                </div>
                <p className="cardd--text">Find us online</p>
                <div className="cardd--icons">
                  <div className="cardd--icons__icon">
                    <a
                      href="https://www.linkedin.com/company/weaverbot-ai/about/?viewAsMember=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </div>
                  <div className="cardd--icons__icon">
                    <a
                      href="https://www.facebook.com/WeaverPlatform/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-square" />
                    </a>
                  </div>
                  <div className="cardd--icons__icon">
                    <a
                      href="https://twitter.com/weaverbot_ai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </div>
                </div>
              </section>
            </section>

            <div className="copy">
              {/* <p className="uppercase">Our partners and distributors</p>
              <div className="copy--grid">
                <a href="http://www.wagaplatform.com/">
                  <img src="/img/nfc.png" alt="NFCSB" />
                </a>
                <a href=" https://www.nfinnova.com/">
                  <img src="/img/nfi.png" alt="NFINOVA" />
                </a>
                <a href=" http://aladdingroups.com/agency/about.php">
                  {" "}
                  <img src="/img/aladin.png" alt="ALADDIN" />
                </a>
              </div> */}
              {window.location.pathname == "/" ? (
                <p>
                  Disclaimer: The data in ‘’Numbers behind Weavers success!’’
                  were drawn from Raiffeisen bank July 2019 reports. The
                  presented conversations were taken from the period between the
                  launch of the chatbot , January 2018, and July 2018.
                </p>
              ) : null}
              <p className="kag">
                Created by{" "}
                <a href="https://www.kag.marketing">
                  KickAssGrowth Digital Marketing Agency
                </a>
              </p>
            </div>
          </>
        </MediaQuery>
      </section>
    </section>
  );
}
