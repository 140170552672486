import React from "react";
import { requestValidation, APIKEY } from "../Helpers";
import { AlertContext } from "../app";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

import axios from "axios";

export default function RequestDemo() {
  const [firstName, setFirstName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [check, setCheck] = React.useState(false);
  const [bot, setBot] = React.useState(false);
  const { visible, hideForm, setAlert } = React.useContext(AlertContext);
  const [active, setActive] = React.useState(0);
  const [isClicked, setIsclicked] = React.useState(false);

  const activate = param => {
    setActive(param);
  };

  const handleChange = value => {
    // console.log("Captcha value:", value);
    // if value is null recaptcha expired
    if (value != null) setBot(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsclicked(true);
    setTimeout(() => {
      setIsclicked(false);
    }, 2000);
    const validation = requestValidation(firstName, email, message, check, bot);

    if (validation === true) {
      axios
        .post("api/requestDemo", {
          firstName,
          email,
          companyName,
          message
        })
        .then(res => {
          if (res.status === 200) {
            setFirstName("");
            setEmail("");
            setCompanyName("");
            setMessage("");
            setCheck(false);
            setAlert(res.data, "success");
          }
        })
        .catch(err => setAlert(err, "danger"));
    } else {
      setAlert(validation, "danger");
    }
  };

  return (
    <div
      className={
        visible === true
          ? "request-demo request-demo__active"
          : "request-demo request-demo__hide"
      }
    >
      <section className="request-demo__container">
        <div className="request-demo__button">
          <p onClick={hideForm}>
            <i className="fas fa-times" />
          </p>
        </div>
        <div className="request-demo__header">
          <h2>REQUEST DEMO</h2>
          <p>
            The best conversational AI platform for clients who are looking for
            an all-in-one interface with simple integration and a cost-effective
            business model.
          </p>
        </div>
        <form method="POST" onSubmit={handleSubmit}>
          <section className="request-demo__form">
            <div className="request-demo__form--input">
              <input
                className={active === 1 ? "active" : ""}
                onFocus={() => activate(1)}
                onBlur={() => activate(10)}
                id="first_name"
                onChange={e => setFirstName(e.target.value)}
                type="text"
                placeholder="First name, last name*"
                value={firstName}
              />
            </div>
            <div className="request-demo__form--input">
              <input
                className={active === 2 ? "active" : ""}
                onFocus={() => activate(2)}
                onBlur={() => activate(10)}
                id="email"
                onChange={e => setEmail(e.target.value)}
                type="email"
                placeholder="E-mail*"
                value={email}
              />
            </div>
          </section>
          <div className="request-demo__form--input">
            <input
              className={active === 3 ? "active" : ""}
              onFocus={() => activate(3)}
              onBlur={() => activate(10)}
              id="company_name"
              onChange={e => setCompanyName(e.target.value)}
              type="text"
              placeholder="Company"
              value={companyName}
            />
          </div>

          <div className="request-demo__form--input">
            <textarea
              className={active === 4 ? "active" : ""}
              onFocus={() => activate(4)}
              onBlur={() => activate(10)}
              id="message"
              onChange={e => setMessage(e.target.value)}
              placeholder="Your message*"
              value={message}
              rows="6"
            />
          </div>

          <div className="request-demo__form--checkbox">
            <input
              id="check"
              onChange={() => setCheck(!check)}
              type="checkbox"
              checked={check}
            />
            <label>
              By submitting your details, you are agreeing to receive
              communications about Weaver resources, events, products, or
              services, and acknowledge that you have read our{" "}
              <Link to="/privacy-policy">privacy policy</Link> and agreed to
              submitting your information.
            </label>
          </div>
          <ReCAPTCHA
            className="captcha"
            sitekey={APIKEY}
            onChange={param => handleChange(param)}
          />

          <div
            className={
              isClicked == false
                ? "request-demo__send-button"
                : "request-demo__send-button active"
            }
          >
            <input type="submit" value="SUBMIT" />
          </div>
        </form>
      </section>
    </div>
  );
}
