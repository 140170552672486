import React from "react";
import Hero from "../../components/WhyWeaver";
import { otherData } from "../../Helpers";
import MediaQuery from "react-responsive";

export default function Covers() {
  const [data] = React.useState(otherData);
  const [index, setIndex] = React.useState(20);
  const isIe = false || !!document.documentMode;
  return (
    <>
      <MediaQuery minWidth={770}>
        <section className="covers container">
          <h2>WEAVER ALSO COVERS</h2>
          <section className="row justify-content-center covers--cards">
            {data.map((el, i) => (
              <section key={i} className="col-lg-3 mr-3 card-other">
                <div className="card-other__header">
                  <div className="card-other__header--icons align-items-center m-auto">
                    <div className={isIe == true ? "mt-3" : ""}>
                      <img
                        src={`/img/covers/${el.icon}.png`}
                        alt="retail chatbot, healthcare chatbot, e-commerce ai, automotive ai, hospitality chatbot, virtual assistant, ai virtual assistant"
                      />
                    </div>
                    <div className={isIe == true ? "mt-3" : ""}>
                      <h3>{el.title}</h3>
                    </div>
                  </div>
                  <div className="card-other__header--description to-left">
                    <p>{el.description}</p>
                  </div>
                </div>
                <div className="card-other__body">
                  <p>{el.body}</p>
                </div>
              </section>
            ))}
          </section>
        </section>
      </MediaQuery>
      <MediaQuery maxWidth={769}>
        <section className="covers">
          <h2>WEAVER ALSO COVERS</h2>
          <section className="covers--cards">
            {data.map((el, i) => (
              <section key={i} className="card-other-res">
                <div className="card-other-res__header">
                  <div className="card-other-res__header--icons">
                    <div>
                      <img
                        src={`/img/covers/${el.icon}.svg`}
                        alt="retail chatbot, healthcare chatbot, e-commerce ai, automotive ai, hospitality chatbot, virtual assistant, ai virtual assistant"
                      />
                    </div>
                    <div>
                      <h3>{el.title}</h3>
                    </div>
                  </div>
                </div>
                <div className="card-other-res__body to-left">
                  <div className="card-other-res__header--description ">
                    <p className="bold">{el.description}</p>
                    <p
                      className={index === i ? "read-more active" : "read-more"}
                      onClick={() => setIndex(i)}
                    >
                      Read more >{" "}
                    </p>
                    <div
                      className={index === i ? "drop-down active" : "drop-down"}
                    >
                      <p>{el.body}</p>
                    </div>
                  </div>
                </div>
              </section>
            ))}
          </section>
        </section>
      </MediaQuery>
    </>
  );
}
