import React from "react";
import MediaQuery from "react-responsive";

export default function Process() {
	const [active, setActive] = React.useState(false);
	return (
		<>
			<MediaQuery minWidth={770}>
				<div className="process container">
					<h2>Process</h2>
					<section className="process__header">
						<p className="small-p-tag">
							A well thought-out and implemented process is the key base of our
							platform. Beginning with clear case definition, Weaver is
							developed for each client’s specific needs, and then adapted in
							cooperation with the client. This gives us a strong starting point
							for the platform’s learning process and base cases.
						</p>

						<div className="process__header--image">
							<img
								src="/img/components/process.png"
								alt="Process"
								alt="nlp, virtual assistant, deep learning, machine learning, deep integration, omnichannel, intelligent talk"
							/>
						</div>
						<div className="process__header--second">
							<p>
								The first step of the development is the analysis and definition
								of the desired conversational scenarios. This process supports
								one of the most important building points – the conversational
								diagrams and training sets for the creation of machine learning
								algorithms. This ultimately leads to the testing of all
								previously programmed processes, which is done in order to check
								whether Weaver’s conversational capabilities reach a
								satisfactory level. All corrections and initial quality checking
								are done simultaneously during the conversation improvement
								step.
							</p>
							<p>
								After all the checkpoints are completed, the platform enters the
								second phase: conversation fine tuning. This is when
								conversation quality is improved and tested out by the
								development and testing teams. When all the details are set up,
								the platform is able to respond to requests seamlessly, and can
								be safely integrated in the client's backend system.
							</p>
						</div>
					</section>
				</div>
			</MediaQuery>
			<MediaQuery maxWidth={769}>
				<div className="process container">
					<h2>Process</h2>
					<section className="process__header">
						<p>
							A well thought-out and implemented process is the key base of our
							platform.
						</p>
						<p
							className={active === true ? "linq" : "linq active"}
							onClick={() => setActive(true)}
						>
							Read more >
						</p>
						<div className={active === true ? "holder" : "holder active"}>
							<p>
								Beginning with clear case definition, Weaver is developed for
								each client’s specific needs, and then adapted in cooperation
								with the client. This gives us a strong starting point for the
								platform’s learning process and base cases. The first step of
								the development is the analysis and definition of the desired
								conversational scenarios. This process supports one of the most
								important building points – the conversational diagrams and
								training sets for the creation of machine learning algorithms,
								which ultimately leads to the testing of all previously
								programmed processes, in order to check whether Weaver’s
								conversational capabilities reach a satisfactory level. All
								corrections are done simultaneously during the conversation
								improvement step, as well as the initial training quality
								checking.
							</p>
							<p>
								After all the checkpoints are completed, the platform enters the
								second phase, which is conversation fine tuning. This is when
								conversation quality is improved and tested out by the
								development and testing teams. When all the details are set up,
								the platform is able to respond to requests seamlessly, and can
								be safely integrated in the client's backend system.
							</p>
						</div>

						<div className="process__header--image">
							<img
								src="/img/components/process-mobile.png"
								alt="Process"
								alt="nlp, virtual assistant, deep learning, machine learning, deep integration, omnichannel, intelligent talk"
							/>
						</div>
						<div className="process__header--second" />
					</section>
				</div>
			</MediaQuery>
		</>
	);
}
