import React, { lazy } from "react";
import { isAuthenticated, validateArticle, fetchCookie } from "../../Helpers";
import axios from "axios";
import { AlertContext } from "../../app";
import Page404 from "../404";
import CKEditor from "ckeditor4-react";

export default function AddArticle() {
  const [article, setArticle] = React.useState({
    title: "",
    caption: "",
    slug: "",
    content: "",
    seo: "",
    img: null
  });

  const refFile = React.useRef(null);
  const { setAlert } = React.useContext(AlertContext);

  const setImg = param => {
    setArticle({ ...article, img: param });
  };

  const extract64 = () => {
    if (typeof refFile.current.files[0] === "undefined") {
      setAlert("Image field is Required! Please Try again", "danger");
    } else {
      if (refFile.current.files[0].type === "image/png") {
        let file = refFile.current.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
          let result = reader.result;
          setImg(result);
          setTimeout(() => {
            setImg(result);
          }, 1000);
        };
        reader.onerror = function(error) {
          console.log(error);
        };
      } else {
        setAlert("Image isn't in .png format! Please try again", "danger");
      }
    }
  };

  const submitArticle = () => {
    setTimeout(() => {
      if (typeof refFile.current.files[0] === "undefined") {
        setAlert("Image field is Required! Please Try again", "danger");
      } else if (refFile.current.files[0].type != "image/png") {
        setAlert("Image isn't in .png format! Please try again", "danger");
      } else {
        validateArticle(article)
          .then(() => {
            axios
              .post("/api/article", article, {
                headers: {
                  Authorization: fetchCookie("x-auth")
                }
              })
              .then(res => {
                window.location.href = `/blog/${article.slug}`;
              })
              .catch(err => setAlert(err.response.data.messages[0], "error"));
          })
          .catch(err => setAlert(err));
      }
    }, 500);
  };

  const resetAlert = () => {
    setTimeout(() => {
      setAlert("");
    }, 2500);
  };

  return isAuthenticated() ? (
    <>
      <section className="insert-page">
        <section className="insert-page--form container">
          <h1>Add Article</h1>
          <form
            className="insert-page--form container"
            encType="multipart/form-data"
            onSubmit={e => {
              e.preventDefault();
              submitArticle();
            }}
          >
            <input
              onChange={e => setArticle({ ...article, title: e.target.value })}
              placeholder="Title"
              value={article.title}
            />
            <input
              onChange={e => setArticle({ ...article, seo: e.target.value })}
              placeholder="Seo Keywords"
              value={article.seo}
            />
            <input
              onChange={e =>
                setArticle({ ...article, caption: e.target.value })
              }
              placeholder="Caption"
              value={article.caption}
            />
            <div className="insert-page--form--slug">
              <div className="insert-page--form--slug--text">{`/blog/`}</div>
              <input
                onChange={e => setArticle({ ...article, slug: e.target.value })}
                placeholder="Article URL (automatically starts with /blog/)"
                value={article.slug}
              />
            </div>
            <CKEditor
              data=""
              onChange={event => {
                setArticle({ ...article, content: event.editor.getData() });
              }}
              config={{
                filebrowserUploadUrl: "/api/upload",
                extraPlugins: "justify,showblocks,embed",
                embed_provider:
                  "https://ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}"
              }}
            />
            <input type="file" onChange={extract64} ref={refFile} />
            <input
              className="button"
              type="submit"
              value="Submit New Article"
            />
          </form>
        </section>
        /
      </section>
    </>
  ) : (
    <Page404 />
  );
}
