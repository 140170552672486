import React from "react";
import MediaQuery from "react-responsive";


export default function Architecture() {
  const [active, setActive] = React.useState(false);
  return (
    <>
      <MediaQuery minWidth={770}>
        <div className="architecture container">
          <h2>Architecture</h2>
          <section className="architecture__header">
            <p>
              Weaver’s application programming interface is the central component of
              the platform itself. It processes messages, integrates all natural
              language processing modules, routes traffic, and much more. If
              necessary, chat platform connectors can be added through the API.
              Furthermore, admin tools are also available through the application
              programming interface.
        </p>
            <p>
              Weaver is centralized, and stores all of its conversations in its API.
              In fact, not only does it store conversations, it also acquires
              relevant information about its users that can later on be used for
              personalized offers or analytics.
        </p>
            <div className="architecture__header--image">
              <img src="/img/components/architecture.png" alt="nlp, virtual assistant, deep learning, machine learning, deep integration, omnichannel, intelligent talk" />
            </div>
          </section>
        </div>

      </MediaQuery>
      <MediaQuery maxWidth={769}>
        <div className="architecture container">
          <h2>Architecture</h2>
          <section className="architecture__header">
            <p>
              Weaver’s application programming interface is the central component of
              the platform itself.
        </p>
            <p className={active === true ? 'linq' : 'linq active'} onClick={() => setActive(true)}>Read more ></p>
            <div className={active === true ? 'holder' : 'holder active'}>
              <p>
                It processes messages, integrates all natural
                language processing modules, routes traffic, and much more. If
                necessary, chat platform connectors can be added through the API.
                Furthermore, admin tools are also available through the application
                programming interface.
                Weaver is centralized, and stores all of its conversations in its API.
                In fact, not only does it store conversations, it also acquires
                relevant information about its users that can later on be used for
                personalized offers or analytics.
        </p>
            </div>
            <div className="architecture__header--image">
              <img src="/img/components/architecture.png" alt="nlp, virtual assistant, deep learning, machine learning, deep integration, omnichannel, intelligent talk" />
            </div>
          </section>
        </div>
      </MediaQuery>
    </>
  );
}
