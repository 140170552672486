import React from "react";
import axios from "axios";
import { AlertContext } from "../../app";
import { validateForm, APIKEY } from "../../Helpers";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [active, setActive] = React.useState(0);
  const [bot, setBot] = React.useState(false);

  const { setAlert } = React.useContext(AlertContext);

  const handleSumit = e => {
    e.preventDefault();
    const validation = validateForm(name, email, message, bot);

    if (validation === true) {
      axios
        .post("api/contactEmail", { name, email, company, message })
        .then(res => {
          if (res.status === 200) {
            setName("");
            setEmail("");
            setCompany("");
            setMessage("");
            setAlert(res.data, "success");
            setBot(false);
          }
        })
        .catch(() =>
          setAlert("Error while sending message! Please try again.", "danger")
        );
    } else {
      setAlert(validation, "danger");
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="keyword" content="service solutions" />
        <meta
          name="information"
          content="services, solutions, chatbot services, consultation, chatbot demo, conversational ai, trial run, it company, service solutions, fintech companies"
        />
      </Helmet>
      <div className="contact container">
        <h1>Contact us</h1>
        <section className="row contact__grid">
          <div className="col-lg-4 contact__grid--text">
            <div className="contact__grid--text--card">
              <div className="icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="description">
                <span>
                  64a Zorana Djindjica Blvd. | 11070 <br />
                  Belgrade, Serbia
                </span>
              </div>
            </div>

            <div className="contact__grid--text--card">
              <div className="icon">
                <i className="fas fa-envelope pt-none" />
              </div>
              <div className="description">
                <span>weaver@saga.rs</span>
              </div>
            </div>

            <div className="contact__grid--text--card">
              <div className="social-icon">
                <a
                  href="https://www.linkedin.com/company/weaverbot-ai/about/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin" />
                </a>
              </div>
              <div className="social-icon">
                <a
                  href="https://www.facebook.com/WeaverPlatform/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-square" />
                </a>
              </div>
              <div className="social-icon">
                <a
                  href="https://twitter.com/weaverbot_ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-2 contact__grid--form-right">
            <p>
              Interested in seeing a demo version of Weaver, or simply want to
              ask us a question?{" "}
            </p>
            <p> Fill out the form below, and we’ll get back to you shortly!</p>
            <form
              method="post"
              className="contact--form"
              onSubmit={handleSumit}
            >
              <div className="contact--form__group">
                <input
                  className={active === 1 ? "active" : ""}
                  onFocus={() => setActive(1)}
                  onBlur={() => setActive(0)}
                  id="name"
                  onChange={e => setName(e.target.value)}
                  type="text"
                  placeholder="First name, last name*"
                  value={name}
                />
              </div>
              <div className="contact--form__group">
                <input
                  className={active === 2 ? "active" : ""}
                  onFocus={() => setActive(2)}
                  onBlur={() => setActive(0)}
                  id="email"
                  onChange={e => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email*"
                  value={email}
                />
              </div>
              <div className="contact--form__group">
                <input
                  className={active === 3 ? "active" : ""}
                  onFocus={() => setActive(3)}
                  onBlur={() => setActive(0)}
                  id="company"
                  onChange={e => setCompany(e.target.value)}
                  type="text"
                  placeholder="Company"
                  value={company}
                />
              </div>
              <div className="contact--form__group">
                <textarea
                  className={active === 4 ? "active" : ""}
                  onFocus={() => setActive(4)}
                  onBlur={() => setActive(0)}
                  id="message"
                  onChange={e => setMessage(e.target.value)}
                  placeholder="Your message*"
                  rows="6"
                  value={message}
                />
              </div>
              <ReCAPTCHA sitekey={APIKEY} onChange={() => setBot(true)} />
              <div className="contact__grid--form-right__send-button">
                <input className="" type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}
