import React, { lazy } from "react";
const HeroForm = lazy(() => import('../../components/HeroForm'));
import VideoGrid from "./VideoGrid";
import Covers from "./Covers";
import Robot from "../home/Robot";
import { Helmet } from "react-helmet";

export default function OtherIndustries() {
  const [heroData, setHeroData] = React.useState({
    title: "Other Industries",
    description:
      " The Conversational AI platform for all B2C business models. Weaver canimprove your business conversion, client retention, reduce costs andoffer the full-time customer experience your clients deserve.",
    cls: "other-hero"
  });
  return (
    <>
      <Helmet>
        <title>Other Industries</title>
        <meta name="keyword" content="chatbot industry" />
        <meta
          name="information"
          content="supply chain, supply chain chatbot, automotive, healthcare, healthcare chatbot hospitality, hospitality chatbot, retail, retail chatbot, e-commerce brands, chatbot solutions, automotive chatbots, e-commerce ai, virtual assistant, ai virtual assistant"
        />
      </Helmet>
      <HeroForm data={heroData} />
      <VideoGrid />
      <Covers />
      <Robot />
    </>
  );
}
