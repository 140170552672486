import React from "react";
import { Link } from "react-router-dom";

const validateForm = (name, email, message, bot) => {
  if (name.length) {
    if (email.length) {
      let re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        if (message.length) {
          if (bot === true) {
            return true;
          } else {
            return "Please verify that you are not an robot";
          }
        } else {
          return "The message field is required, but missing. Please try again.";
        }
      } else {
        return "The email address is invalid! Please try again.";
      }
    } else {
      return "The email field address is required, but missing!  Please try again.";
    }
  } else {
    return "The name field is required, but missing! Please try again.";
  }
};

const validateHeroForm = (name, email) => {
  if (name.length) {
    if (email.length) {
      let re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        return true;
      } else {
        return "The email address is invalid! Please try again.";
      }
    } else {
      return "The email field address is required, but missing!  Please try again.";
    }
  } else {
    return "The name field is required, but missing! Please try again.";
  }
};

const APIKEY = "6LcV77wUAAAAAPUfhl6Y1-jk3ccnhqtkBwGC-kZ8";

const advantages = [
  {
    title: "Instant gratification",
    img: "1.png",
    text:
      "Your clients don’t have to wait on hold for the next available operator to respond to their inquiry, losing time out of busy schedules and becoming frustrated by slow service. Instead, Weaver offers your clients immediate and detailed answers and instant gratification as many times as they need, without creating an endless queue of impatient clients and upset clients."
  },
  {
    title: "Extended Hours",
    img: "2.png",
    text:
      "Employees have fixed hours of operation, are entitled to holidays, vacations, and sick days, which means that at some point or other, there isn’t enough manpower to handle all client calls. Weaver meets your clients’ needs after office hours, during holidays, even in the middle of the night. Our chatbot works consistently and tirelessly, at the ready when humans are unavailable."
  },
  {
    title: "Seamless conversation",
    img: "3.png",
    text:
      "In case you make a mistake or a typo addressing the issue in communication with Weaver, do not worry. Weaver understands typos, misplaced punctuation, and errors in context, slang,  interprets them and correctly executes all commands in stride with the conversational flow, eliminating the hassle of retyping."
  },
  {
    title: "Advisory role",
    img: "4.png",
    text:
      "The idea of a chatbot isn’t new -  what is new, indeed, is the way that Weaver works - it is not just a chatbot, but has evolved to be a virtual assistant that boasts its advisory role which helps users make important decisions, apart from having a big answer base. Having all of the perks of the latest advances in technology, Weaver is set to become the name of the game in the world of AI."
  },
  {
    title: "Increase in </br> customer base",
    img: "5.png",
    text:
      "Weaver can help you extend your reach and enlarge your customer base. Millennials want and need to have instant answers at their fingertips at all times, they use every kind of channel out there, and are constantly looking for upgrades and advancements. Weaver keeps up with everything that just about any customer base would expect from a company."
  },
  {
    title: "Overall customer satisfaction",
    img: "6.png",
    text:
      "Powered by AI, chatbots are excellent multitaskers and are able to execute hundreds, if not thousands, of tasks at the same time. Their swiftiness, service quality, and 24/7 availability are what ultimately lead to customer satisfaction. However, acting as a business advisor to clients is where Weaver truly excels, providing thoughtful and intuitive tips and tricks for all client services."
  }
];

const requestValidation = (firstName, email, message, check, bot) => {
  if (firstName.length) {
    if (email.length) {
      let re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        if (message.length) {
          if (bot === true) {
            if (check === true) {
              return true;
            } else {
              return "Please confirm check button!";
            }
          } else {
            return "Please verify that you are not an robot";
          }
        } else {
          return "The Message field is required, but missing! Please try again.";
        }
      } else {
        return "The E-mail address is invalid! Please try again.";
      }
    } else {
      return "The E-mail field is required, but missing! Please try again.";
    }
  } else {
    return "The First name and Last name field is required, but missing! Please try again.";
  }
};

const downloadValidation = (firstName, lastName, email, message) => {
  if (firstName.length) {
    if (lastName.length) {
      if (email.length) {
        let re = /\S+@\S+\.\S+/;
        if (re.test(email)) {
          return true;
        } else {
          return "The E-mail address is invalid! Please try again.";
        }
      } else {
        return "The E-mail field is required, but missing! Please try again.";
      }
    } else {
      return "The Last name field is required, but missing! Please try again.";
    }
  } else {
    return "The First name and Last name field is required, but missing! Please try again.";
  }
};

const dataReferences = [
  {
    url: "raiffeisen-bank",
    desktop: true,
    mobile: false,
  },
  {
    url: "raiffeisen-bank-albania",
    desktop: true,
    mobile: false,
  },
  {
    url: "societe-bank",
    desktop: true,
    mobile: true,
  },
  {
    url: "unicredit-bank",
    desktop: true,
    mobile: true,
  },
  {
    url: "telekom-srbija",
    desktop: true,
    mobile: true,
  },
  {
    url: "raifaisen-logo",
    desktop: false,
    mobile: true,
  },
  {
    url: "BAPUSS",
    desktop: true,
    mobile: true,
  },
];

const features = [
  {
    nav: "Intelligent talk",
    reNav: "Intelligent<br/> talk",
    title: "Intelligent talk",
    reTitle: "<span>Intelligent talk<span>",
    content:
      "The use of artificially intelligent systems is on the rise, but the concern about the genuine simulation of human conversation still exists. The Weaver bot module solves this issue by understanding the flow of the human mind, giving it the ability to keep up when the user spontaneously changes their mind, and seamlessly and intelligently continue the conversation.",
    video: "inteligent",
    position: 0
  },
  {
    nav: "Deep Integration",
    reNav: "Deep<br/> Integration",
    title: "Deep Integration",
    reTitle: "<span>Deep Integration<span>",
    content:
      "Complex software solutions often come with several components that must be compatible and up to date, which isn’t always the case, leading to issues during runtime, and dissatisfied clients. Weaver is exactly the opposite: a single solution easily integrated in all core systems of any company’s software, full coverage of processes deliverable to the end users, all in one place.",
    video: "deep",
    position: 1,
    borderLeft: "lef-hidden",
    borderRigh: "right-hidden"
  },
  {
    nav: "OmniChannel Experience",
    reNav: "Omni -<br/>Channel",
    title: "Title 3",
    reTitle: "OmniChannel <br/> <span>Experience<span>",
    content:
      "In an era of total information accessibility, users tend to prefer the channels they use in their everyday lives. Most companies think they can’t possibly be present on every channel out there without major expenses. Weaver solves this by being present and collecting data across all of them, and storing it in one place. This allows for information availability at the clients’ fingertips.",
    video: "omni",
    position: 2
  },
  {
    nav: "Multi-Intent",
    reNav: "Multi-<br/>Intent",
    title: "Title 4",
    reTitle: "<span>Multi-Intent<span>",
    content:
      "Customer service agents can often be overbooked by numerous inquiries and can’t manage to immediately answer all of them. The Weaver chatbot is capable of handling numerous pieces of different types of information all at once. Be it meaning or context, Weaver can easily comprehend end users’ statements that have more than one intention.",
    video: "intent",
    position: 3,
    borderLeft: "lef-hidden",
    borderRigh: "right-hidden"
  },
  {
    nav: "Multilingualism",
    reNav: "Multi-<br/>ingualism",
    reTitle: "<span>Multilingualism<span>",
    title: "Title 5",
    content:
      "Our Weaver is fluent in the majority of the world’s languages. Once the selected language scheme is fully implemented, Weaver understands the most common international languages (English, Chinese, German, etc.) equally as well as the less common ones (Serbian, Armanian, Greek, etc.), allowing for a friendlier and more personal user experience. ",
    video: "multi",
    position: 4
  },
  {
    nav: "Voice Ready",
    reNav: "Voice-<br/>Ready",
    reTitle: "<span>Voice Ready<span>",
    title: "Title 6",
    content:
      "Research has shown that 51% of people who shop using the auditory method use it to research products and services. This was a green light for an additional feature to extend the customer service experience - voice-activated assistance. This enables Weaver to interact and communicate through voice, accept commands in oral or written form, and respond vocally.",
    video: "voice",
    position: 5,
    borderLeft: "lef-hidden",
    borderRigh: "right-hidden"
  }
];

const endCustomer = [
  {
    title: "FAST RESPONSE",
    second: "TO INQUIRIES"
  },
  {
    title: "NO QUEUE",
    second: " WAITING"
  },
  {
    title: "PERSONALIZED",
    second: "OFFERS"
  },
  {
    title: "SCHEDULING",
    second: " MADE EASY"
  }
];

const dataBenefits = [
  {
    icon: "finance",
    title: "FINANCE",
    description:
      "Cost-effective and available 24/7, Weaver is the perfect solution for banks and credit unions. It provides advisory role allowing the clients a way for clients to check their bank accounts, carry out transactions, set up new accounts, apply for loans, and more. Draw in new clientele with Weaver’s comprehensive and intelligent responses - it provides the conversational feel of a call centre, without the wait time.",
    link: "finance-ibanking"
  },
  {
    icon: "insurance",
    title: "INSURANCE  COMPANIES",
    description:
      "With life moving faster than ever, Weaver presents the perfect solution for the clients of your company, who need to file a claim but are always on the move. With Weaver, clients can become informed of their options, as well as file claims directly from their smartphones, without having to take time out of their busy schedules.",
    link: "insurance-chatbot"
  },
  {
    icon: "telco",
    title: "TELECOMMUNICATION",
    description:
      "Looking at the nature of inquiries handled by telecom agents, many of them that must be quickly carried out and most require agents to connect with several departments. Weaver meets your clients’ needs after office hours, even in the middle of the night. It offers full and flawless telecommunication support, resulting in lower operating costs and an increase in efficiency and client satisfaction.",
    link: "telecom-solution"
  }
];

const whyBenefits = [
  {
    icon: "finance",
    title: "FINANCE",
    description:
      "Cost-effective and available 24/7, Weaver is the perfect solution for banks and credit unions.  It provides advisory role allowing the clients a way to check their bank accounts, carry out transactions, set up new accounts, apply for loans, and more. Draw in new clientele with Weaver’s comprehensive and intelligent responses - the conversational feel of a call centre, without the wait time.",
    link: "finance-ibanking"
  },
  {
    icon: "insurance",
    title: "INSURANCE  COMPANIES",
    description:
      "With life moving faster than ever, Weaver presents the perfect solution for the clients of your company, who need to file a claim but are always on the move. With Weaver, clients can become informed of their options, as well as file claims directly from their smartphones, without having to take time out of their busy schedules.",
    link: "insurance-chatbot"
  },
  {
    icon: "telco",
    title: "TELECOMMUNICATION",
    description:
      "Looking at the nature of inquiries handled by telecom agents, many of them that must be quickly carried out and most require agents to connect with several departments. Weaver meets your clients’ needs after office hours, even in the middle of the night. It offers full and flawless telecommunication support, resulting in lower operating costs and an increase in efficiency and client satisfaction.",
    link: "telecom-solution"
  }
];

const heroPerecentageFinance = [
  {
    percent: "86%",
    perDesc:
      "of financial institutions </br> stated chatbots will help </br> engage millennials"
  },
  {
    percent: "$7.3",
    perDesc:
      "billion in operational cost savings from using chatbots within banking will be reached on a global level by 2023"
  },
  {
    percent: "31x",
    perDesc:
      "productivity increase in successful banking chatbot interactions which will take place between 2019 and 2023"
  }
];

const heroPerecentageInsurance = [
  {
    percent: "37%",
    perDesc: "expect to get quick answers to questions in an emergency"
  },
  {
    percent: "64%",
    perDesc: "believe the top benefit is the ability to get 24-hour service"
  },
  {
    percent: "86%",
    perDesc: "believe that chatbots will help to engage millennials "
  }
];

const heroPerecentageTelco = [
  {
    percent: "4 x",
    perDesc: "The speed of client communication "
  },
  {
    percent: "5 x",
    perDesc: "more interactions than traditional channels"
  },
  {
    percent: "Top 3",
    perDesc:
      "Telecom is one of the TOP 3 industries to financially benefit from chatbots"
  }
];

const problemSolutionFinance = [
  {
    titleProblem: "After hours support",
    image: "hours",
    descriptionProblem:
      "Your clients are in a bind - they want to transfer their funds from one account to another, after hours because they work late, and something goes wrong. It’s an emergency, and they need the funds, but they have no one to contact.",
    titleSolution: "Extended hours in </br> Customer support ",
    descriptionSolution:
      "Chatbots have shaped a brand new relationship between the customer and the bank, based on 24/7 availability and instant gratification. They eliminate customer queues by providing quick, world-class service to everyone who sends urgent inquiries such as a need to transfer a sum of money or report a missing credit card."
  },
  {
    titleProblem: "A need for a financial advisor",
    image: "advisor",
    descriptionProblem:
      "Your employees have their hands full, and when it comes to multitasking, one second of broken concentration is enough to bring the whole balancing act tumbling down. Perhaps your clients want to quickly check their account balance, or view all of their budgeting options, but it’s taking too long to get through to a representative.",
    titleSolution: "Advisors at the ready ",
    descriptionSolution:
      "Weaver is available on the channels your customers are, and it knows how to handle almost every client care situation. Just like a human financial advisor, Weaver knows how to give your clients the information such as how much they spent on Uber, or shopping during the last month. Weaver is able to provide these kind of answer which cannot be given in call centres or branch offices."
  },
  {
    titleProblem: "Decrease in productivity",
    image: "decrease",
    descriptionProblem:
      "Clients often spend a lot of time with customer service because they find themselves repeating information that they’ve already given. When it comes to financial transactions such as transferring funds or paying bills, repeating the same information constantly may lead to errors and miscommunication.",
    titleSolution:
      "Elevated productivity and </br> satisfaction among employees",
    descriptionSolution:
      "Weaver makes sure to “transfer $20 to my sister Linda” or “pay the phone bill” without asking for more information than that, because it already knows who Linda is, and how to pay the phone bill from previously executed transactions. This way, employees can focus on customer requests that add value to their everyday work, increasing their efficiency by up to 80%."
  }
];

const problemSolutionFinanceRes = [
  {
    titleProblem: "After hours support",
    image: "hours",
    descriptionProblem:
      "Your clients are in a bind - they want to transfer their funds from one account to another, after hours because they work late, and something goes wrong. It’s an emergency, and they need the funds, but they have no one to contact.",
    titleSolution: "Extended hours in  Customer </br><span>support</span>",
    descriptionSolution:
      "Chatbots have shaped a brand new relationship between the customer and the bank, based on 24/7 availability and instant gratification. They eliminate customer queues by providing quick, world-class service to everyone who sends urgent inquiries such as a need to transfer a sum of money or report a missing credit card."
  },
  {
    titleProblem: "A need for a financial advisor",
    image: "advisor",
    descriptionProblem:
      "Your employees have their hands full, and when it comes to multitasking, one second of broken concentration is enough to bring the whole balancing act tumbling down. Perhaps your clients want to quickly check their account balance, or view all of their budgeting options, but it’s taking too long to get through to a representative.",
    titleSolution: "Advisors at the </br><span>ready</span>",
    descriptionSolution:
      "Weaver is available on the channels your customers are, and it knows how to handle almost every client care situation. Just like a human financial advisor, Weaver knows how to give your clients the information such as how much they spent on Uber, or shopping during the last month. Weaver is able to provide these kind of answer which cannot be given in call centres or branch offices."
  },
  {
    titleProblem: "Decrease in productivity",
    image: "decrease",
    descriptionProblem:
      "Clients often spend a lot of time with customer service because they find themselves repeating information that they’ve already given. When it comes to financial transactions such as transferring funds or paying bills, repeating the same information constantly may lead to errors and miscommunication.",
    titleSolution:
      "Elevated productivity and  satisfaction among </br><span>employees<span>",
    descriptionSolution:
      "Weaver makes sure to “transfer $20 to my sister Linda” or “pay the phone bill” without asking for more information than that, because it already knows who Linda is, and how to pay the phone bill from previously executed transactions. This way, employees can focus on customer requests that add value to their everyday work, increasing their efficiency by up to 80%."
  }
];

const problemSolutionInsurance = [
  {
    titleProblem: "After hours emergency inquiries",
    image: "hours",
    descriptionProblem:
      "When you only have a certain amount of time to file an insurance claim or report an incident, every minute counts. What happens when it’s after hours, and there’s no one to take your call? Perhaps you’re going on a trip and you’ve forgotten to buy travel insurance, but you’re already in the car, heading to the airport?",
    titleSolution: "24 hour availability",
    descriptionSolution:
      "Customers need you when they need you and there is no compromise over that. With Weaver, clients are able to file their claims as soon as possible. Clients get information as soon as they send Weaver their first message, whether it’s about a current situation with their account, or because they’d like to buy new products, such as travel insurance."
  },
  {
    titleProblem: "The Need for Human Conversation",
    image: "need",
    descriptionProblem:
      "Even though artificial intelligence is innovating and brings many benefits to the table, some clients still prefer to speak with a human. Dialing through an automated response system and waiting for someone to take your call can be frustrating and can cause greater problems for someone looking for answers.",
    titleSolution: "Process Digitalization",
    descriptionSolution:
      "Even though the first contact for a client might be with a chatbot, Weaver’s platform can offer the option to continue your conversation with an agent. The best part is that the agent will receive a copy of the conversation, thus knowing what’s already been said, so there’s no on-boarding and repetition."
  },
  {
    titleProblem: "Time-consuming paperwork",
    image: "consuming",
    descriptionProblem:
      "Not everyone has the luxury of waiting in a queue to speak with an insurance broker, especially when they’re looking for last-minute insurance deals before, say, an upcoming trip. Often, going through a representative becomes tedious and repetitive, especially when we take into account that some of them work slower than others.",
    titleSolution: "Offered Automatization",
    descriptionSolution:
      "Weaver’s platform allows your clients to automatically fill in the necessary details for insurance, without having to repeat themselves. This allows them to receive custom-tailored offers without having to wait on hold, and having to repeat themselves to representatives."
  }
];

const problemSolutionInsuranceRes = [
  {
    titleProblem: "After hours emergency inquiries",
    image: "hours",
    descriptionProblem:
      "When you only have a certain amount of time to file an insurance claim or report an incident, every minute counts. What happens when it’s after hours, and there’s no one to take your call? Perhaps you’re going on a trip and you’ve forgotten to buy travel insurance, but you’re already in the car, heading to the airport?",
    titleSolution: "24 hour </br><span>availability<span>",
    descriptionSolution:
      "Customers need you when they need you and there is no compromise over that. With Weaver, clients are able to file their claims as soon as possible. Clients get information as soon as they send Weaver their first message, whether it’s about a current situation with their account, or because they’d like to buy new products, such as travel insurance."
  },
  {
    titleProblem: "The Need for Human Conversation",
    image: "need",
    descriptionProblem:
      "Even though artificial intelligence is innovating and brings many benefits to the table, some clients still prefer to speak with a human. Dialing through an automated response system and waiting for someone to take your call can be frustrating and can cause greater problems for someone looking for answers.",
    titleSolution: "Process </br>Digitali</br><span>zation</span>",
    descriptionSolution:
      "Even though the first contact for a client might be with a chatbot, Weaver’s platform can offer the option to continue your conversation with an agent. The best part is that the agent will receive a copy of the conversation, thus knowing what’s already been said, so there’s no on-boarding and repetition."
  },
  {
    titleProblem: "Time-consuming paperwork",
    image: "consuming",
    descriptionProblem:
      "Not everyone has the luxury of waiting in a queue to speak with an insurance broker, especially when they’re looking for last-minute insurance deals before, say, an upcoming trip. Often, going through a representative becomes tedious and repetitive, especially when we take into account that some of them work slower than others.",
    titleSolution: "Offered </br>Automati</br><span>zation</span>",
    descriptionSolution:
      "Weaver’s platform allows your clients to automatically fill in the necessary details for insurance, without having to repeat themselves. This allows them to receive custom-tailored offers without having to wait on hold, and having to repeat themselves to representatives."
  }
];

const problemSolutionTelco = [
  {
    titleProblem: "Large funds for limited service",
    image: "founds",
    descriptionProblem:
      "Sometimes it’s all about quality instead of quantity, and call centres are no exception. With ever-rising costs of keeping a business at its maximum potential, cutting back can be an issue. Clients expect their questions to be answered, but call centres can be a black hole for funds.",
    titleSolution: "Cost efficiency",
    descriptionSolution:
      "For most telecommunication companies, clients are asking for swift answers to their inquiries which overflow employees. Weaver is able to help them by taking over these inquiries, leaving the employees feel free to do more creative jobs, and effectively cutting back on the need for night shifts, while providing instant gratification which leads to exceptional support that customers expect."
  },
  {
    titleProblem: "Account setting options and issues",
    image: "account",
    descriptionProblem:
      "Creating, activating, and deactivating account services can be daunting, and a cause for endless frustration. Clients might want to set up new services or deactivate old ones, or simply change account settings at a moment’s notice. They might want to change their phone numbers, packages, or switch to roaming charges without having to wait to speak with someone.",
    titleSolution: "Platform with a central data core",
    descriptionSolution:
      "Weaver’s platform allows clients to immediately switch their account types without going through the hassle of entering all of their personal information multiple times. Activation, deactivation, and updating settings has never been easier, thanks to Weaver’s centralized data core. All client account changes are effective immediately, and automatically updated across all channels."
  },
  {
    titleProblem: "Upselling your products",
    image: "upselling",
    descriptionProblem:
      "Often it’s difficult to find the right moment to upsell your services to existing customers. Data collection is tricky and requires attention to detail, and even then, the timing may not always be just right to present a new product to customers who usually only contact customer support with specific issues.",
    titleSolution: "Generation of unlimited statistics",
    descriptionSolution:
      "Probably the greatest advantage of any chatbot is its ability to automatically store conversations, giving a business the means to refer to them should it need to analyze the trends in its customer base. With Weaver, you have the ability to generate an unlimited amount of statistics, which can help with audience segmentation and future product placements in a way that naturally leads to a greater conversion rate."
  }
];

const problemSolutionTelcoRes = [
  {
    titleProblem: "Large funds for limited service",
    image: "founds",
    descriptionProblem:
      "Sometimes it’s all about quality instead of quantity, and call centres are no exception. With ever-rising costs of keeping a business at its maximum potential, cutting back can be an issue. Clients expect their questions to be answered, but call centres can be a black hole for funds.",
    titleSolution: "Cost </br><span>efficiency</span>",
    descriptionSolution:
      "For most telecommunication companies, clients are asking for swift answers to their inquiries which overflow employees. Weaver is able to help them by taking over these inquiries, leaving the employees feel free to do more creative jobs, and effectively cutting back on the need for night shifts, while providing instant gratification which leads to exceptional support that customers expect."
  },
  {
    titleProblem: "Account setting options and issues",
    image: "account",
    descriptionProblem:
      "Creating, activating, and deactivating account services can be daunting, and a cause for endless frustration. Clients might want to set up new services or deactivate old ones, or simply change account settings at a moment’s notice. They might want to change their phone numbers, packages, or switch to roaming charges without having to wait to speak with someone.",
    titleSolution: "Platform with a central data </br><span>core</span>",
    descriptionSolution:
      "Weaver’s platform allows clients to immediately switch their account types without going through the hassle of entering all of their personal information multiple times. Activation, deactivation, and updating settings has never been easier, thanks to Weaver’s centralized data core. All client account changes are effective immediately, and automatically updated across all channels."
  },
  {
    titleProblem: "Upselling your products",
    image: "upselling",
    descriptionProblem:
      "Often it’s difficult to find the right moment to upsell your services to existing customers. Data collection is tricky and requires attention to detail, and even then, the timing may not always be just right to present a new product to customers who usually only contact customer support with specific issues.",
    titleSolution: "Generation of unlimited</br><span>statistics</span>",
    descriptionSolution:
      "Probably the greatest advantage of any chatbot is the fact that conversations are automatically stored, and can be referred to in case businesses are in need of trends in their customer base. With Weaver, you have the option of generating unlimited statistics which can help with audience segmentation and future product placements in a way that naturally leads to a greater conversion rate."
  }
];

const financeFeature = [
  {
    image: "talk",
    title: "Intelligent talk",
    description:
      "The Weaver bot module understands the flow of the human mind, giving it the ability to keep up with the user’s spontaneous change of mind, and seamlessly continue the conversation."
  },
  {
    image: "omni",
    title: "Omni-channel Experience",
    description:
      "Weaver chatbot has an omnichannel presence, which translates into seamless interaction between a business and its clients, who have a single user journey, no matter which channel or platform they use. The business has information centralized, from all channels of communication."
  },
  {
    image: "lang",
    title: "Multilingualism",
    description:
      "Once the selected language scheme is fully implemented, Weaver understands the most widely known international languages as well as the less common ones."
  },
  {
    image: "voice",
    title: "Voice Ready",
    description:
      "Weaver can easily interact and communicate through voice commands, accept requests in oral or written form, and by using deep learning, it responds quickly with unique fully customized answers. "
  }
];

const financeInsurance = [
  {
    image: "talk",
    title: "Intelligent talk",
    description:
      "The Weaver bot module understands the flow of the human mind, giving it the ability to keep up with the user’s spontaneous change of mind, and seamlessly continue the conversation."
  },
  {
    image: "deep",
    title: "Deep Integration",
    description:
      "The Weaver solution is easily integrated into all of core systems of any company software. It assures the full coverage of processes deliverable to the end users, all in one place. "
  },
  {
    image: "omni",
    title: "Omni-channel Experience",
    description:
      "Weaver is easily integrated in the most popular channels, offering the same services through various communication platforms and covering the majority of the customer pool."
  },
  {
    image: "lang",
    title: "Multilingualism",
    description:
      "Once the selected language scheme is fully implemented, Weaver understands the most widely spoken international languages and the less common ones, allowing for a friendlier and more personal user experience. "
  }
];

const financeTelco = [
  {
    image: "omni",
    title: "Omni-channel Experience",
    description:
      "Weaver is easily integrated in the most popular channels, offering the same services through various communication platforms and covering the majority of the customer pool."
  },
  {
    image: "ai",
    title: "AI Based Solution",
    description:
      "In keeping up with natural human communication, conversational AI mistakes are automatically corrected and correctly interpreted by the chatbot, allowing for a meaningful and informational dialogue without interruptions and misunderstandings."
  },
  {
    image: "lang",
    title: "Multilingualism",
    description:
      "Once the selected language scheme is fully implemented, Weaver understands the most widely known international languages and the less common ones, allowing for a friendlier and more personal user experience."
  },
  {
    image: "voice",
    title: "Voice Ready",
    description:
      "Weaver can easily interact and communicate through voice commands, accept requests in oral or written form, and by using deep learning, it responds quickly with unique fully customized answers."
  }
];

function createMarkup() {
  return { __html: "First &middot; Second" };
}

const faq = [
  {
    title: "General technology",
    img: "/img/components/faq1.png",
    content: [
      {
        title: "What is a chatbot?",
        text:
          "A chatbot is an innovative and interactive way for brands to communicate with their clients and end-users. By using artificial intelligence modules, the computer program is able to mimic a conversation between two people. It can perform a wide range of tasks, from offering simple answers and asking basic questions, to executing more complex actions such as providing transactional payments, account balances, and more. From finding out the location of the nearest bank branch to booking an exciting trip, a chatbot offers full customer service support in any industry."
      },
      {
        title: "What is Machine Learning and Deep Learning?",
        text:
          "Machine learning is the scientific study of algorithms with the help of which computers are able to learn from any experience and perform tasks  without specific instructions. Deep learning is a part of a wider area of machine learning methods. This popular method uses artificial neural networks in order to understand complex concepts, one of those being natural language processing."
      },
      {
        title: "What is NLP?",
        text:
          "Natural Language Processing (NLP) is an area of AI technology that gives computer systems the ability to process and analyze human conversation by reading, deciphering and understanding natural human language."
      }
    ]
  },
  {
    title: "Weaver chatbot",
    img: "/img/components/faq3.png",
    content: [
      {
        title: "What is the difference between Weaver and any other chatbot?",
        text: `Weaver is not just a chatbot - it is a powerful notification platform that gathers multiple modules, (the chatbot module being one of them) into one functional solution, monitors all communication on various channels, and controls executional processes. 
Thanks to its features, Weaver can understand the majority of the world’s languages, allow customers to ask questions through all integrated communication platforms, and respond to all of them with the help of NLP. What's more, by following a natural conversation flow, it completely simulates human-to-human interaction, providing necessary information in a short amount of time. This leads to delivering an overall satisfactory experience to customers, and in return, many benefits for the company's business. Read more about Weaver’s features and benefits  <a href="/#benefits" style="color: #ffffff;">here</a>.`
      },
      {
        title: "Do I have to implement Weaver to test it?",
        text:
          "No. You can simply request a demo by clicking here, or you can test its demonstration via Facebook Messenger or Viber by chatting with Rea ( Viber / Messenger ) or Sozeta ( Messenger ), our in-production chatbot concept for Raiffeisen Bank and Société Générale. Their default language is Serbian, but you can also test them out in English by choosing your desired language from the menu in the chat window."
      },
      {
        title: "Is the Weaver platform customizable?",
        text:
          "Yes, the Weaver chatbot platform is completely customizable. It can be programmed and customized to create unique a chatbot that best suits your company and industry."
      },
      {
        title: "What channels and social media platforms support Weaver?",
        text:
          "Weaver is supported on various social media and conversational platforms, just a few of them being Viber, Messinger, Slack, Skype and Web chat."
      },
      {
        title: "How does Weaver AI technology work?",
        text: `Weaver is based on six modules:
1) Channel connector
2) The main Weaver API
3) Module for natural language processing
4) Chatbot module engine
5) Administrator tool
6) Connector of external information sources

With the help of the above-mentioned modules, Weaver is able to send a message to the end-user, and is able to articulate quick responses and tasks in forms such as pictures, text, stickers or carousel menus, all with the help of NLP and deep learning. Furthermore, thanks to these modules, Weaver can also work on various communication platforms and offer overall customer service support. `
      }
    ]
  },
  {
    title: "Integration and Security",
    img: "/img/components/faq2.png",
    content: [
      {
        title:
          "How is the Weaver platform integrated, and how does it perform in a client’s backend system?",
        text:
          "The Weaver solution is easily integrated into all core systems of any company. It assures full coverage over processes deliverable to the end users which can be executed through multiple sources. On the other hand, the integrated system optimizes all of the inner workings, connecting the data and calculating the most up to date results for the users’ inquires. Starting with simple account balance checking, moving to complex calculative offers or travel reservations, the integration point enables companies to monitor and control the main core business over multiple channels."
      },
      {
        title:
          "What security measures does Weaver use to protect personal data?",
        text:
          "As far as data security goes, Weaver does not acquire nor accept any personal information from unregistered users. In the case of registered ones, the conversational AI platform only accepts and stores the connection between the user’s unique channel identifier and user’s unique identifier in the bank system. This connection through the unique identificator is used for advanced functions such as money transactions, and bank account balance checks. The users can disable these functions, and the previously mentioned connection will thus be rendered functionless."
      }
    ]
  }
];

const otherIndustries = [
  {
    title: "Healthcare",
    subtitle: "Unleash your support potential with Weaver in Healthcare",
    video: "video/healthcare",
    img: "/img/components/other/health.png",
    content: [
      {
        title: "problem:",
        text:
          "Oftentimes, life throws a curveball and we don’t have time to sit on the phone, on hold, waiting for the next available medical administrative assistant to take our call and book an appointment. Other times, all we need to know is where the closest emergency clinic is, or what the results are from our blood test. This leads to stress, anxiety, and frustration."
      },
      {
        title: "solution:",
        text:
          "Weaver’s platform is available to you 24/7, ready when you are to book your next appointment, or point you in the right direction. Along with reminding you about upcoming appointments, Weaver can let you know what your test results returned, and keep your medical history organized and at your fingertips."
      },
      {
        title: "benefit:",
        text:
          "Providing care and support is something the medical industry should excel at, whatever the cost. Having Weaver on your side allows for your medical practitioners and administrative assistants alike to focus on their jobs stress-free. Weaver is there to help with booking appointments, answering every-day questions, and reminding patients of their results and appointments, while health care workers can do what really matters: saving lives."
      }
    ]
  },
  {
    title: "Hospitality",
    subtitle: "Weaver brings the future of customer support in Hospitality",
    video: "video/hospitality",
    img: "/img/components/other/hospitality.png",
    content: [
      {
        title: "problem:",
        text:
          "Planning a trip can be a hassle, especially when there are so many options to choose from, and you spend time on different websites offering different prices for the same location. It becomes especially frustrating when you’ve spent so much time booking a room, only to find out that it’s been double-booked, or that the hotel isn’t pet friendly and you’ve brought your dog along."
      },
      {
        title: "solution:",
        text:
          "When booking a hotel, be it business or pleasure, Weaver helps you choose the perfect room and there’s no fear of double-booking - everything is in one place, and everyone is on the same page. Weaver also answers all of your inquiries, and provides information on hotel amenities and services."
      },
      {
        title: "benefit:",
        text:
          "With Weaver, employees no longer need to worry about upset guests and client loss with incorrect bookings, or incorrect offers online. All that’s necessary is to update Weaver with all of the necessary information about your hotel, restaurant, etc. and your clients and customers have everything they need to quickly book a pleasant stay, hassle-free."
      }
    ]
  },
  {
    title: "Transportation",
    subtitle: "The conversational AI platform can now ease the trip planning",
    video: "video/avio",
    img: "/img/components/other/avio.png",
    content: [
      {
        title: "problem:",
        text:
          "Planning the perfect trip to fit your budget is time-consuming - it seems that every airline website shows a different price for the same flight, and it’s almost always just about sold out. You’re not sure whether to book, or to wait a bit longer for a better deal but run the risk of losing out."
      },
      {
        title: "solution:",
        text:
          "Weaver is the virtual assistant everyone needs for quick bookings at competitive prices, for flight reminders, and all other information required for a safe and happy flight. Weaver gives you options for the dates you enter, saves you having to dig through different websites, and ensures that your flight is the best one for your trip. This saves you hours of stress and wasted time, and keeps you within you travel budget, ensuring that the flight you book is worth it in every sense."
      },
      {
        title: "benefit:",
        text:
          "Having a platform like Weaver allows for fantastic customer service, and ensures that your clients come to you. Weaver is available at all times of the day and night, and is ready to give your clients the best offers for their trips, keeping their budgets and travel plans in mind. Customer service at its finest!"
      }
    ]
  }
];
const otherData = [
  {
    icon: "1",
    title: "supply chain",
    description:
      "With the power of AI, time becomes irrelevant in the supply chain industry",
    body:
      "As the supply chain industry is slowly becoming more customer-oriented, AI assistants are an important part of this development. The efficiency of Weaver is proven within various processes, such as distributing required information to customers, procurements, sending orders, handling payments and paperwork. The clients’ purchasing decisions are often based on supply chain offers, thus by delivering information quickly and accurately, even outside regular business hours, is the largest advantage for your clients."
  },
  {
    icon: "2",
    title: "Automotive",
    description: "The perfect AI employee is coming to the automotive industry",
    body:
      "Automotive companies hire experienced and creative salespeople in dealerships in order to provide a pleasant customer experience. This can be very expensive. Weaver can act as  a company's virtual assistant, one who is able to provide a lifelike experience, thanks to deep integration and NLP. Guiding customers through the website and discovering their preferences not only increases customer satisfaction, but also simultaneously allows for data collection for future analysis and making targeted offers. "
  },
  {
    icon: "3",
    title: "Retail",
    description: "Weaver empowers the retail industry with the potential of AI",
    body:
      "Retailers are putting a lot of effort into providing a seamless experience for their customers. Furthermore, they want to become a top-of-the-line brand and raise customer satisfaction levels. Weaver provides customers with a shortcut by speeding up the online ordering experience. Whether they are searching for an item that filters can’t find, ordering something new, or making a previously placed order, Weaver can do it all."
  },
  {
    icon: "4",
    title: "Custom industry",
    description:
      "Weaver can unchain the potential of your industry with the power of AI",
    body:
      "Haven't found the industry that you are working in? Not to worry - Weaver’s core platform technology is flexible enough to be implemented in a wide range of industries and companies. Let's talk about options for your industry! Get in touch with us."
  }
];

const createCookie = (cookieName, cookieValue, daysToExpire) => {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  document.cookie =
    cookieName + "=" + cookieValue + "; expires=" + date.toGMTString();
};

const fetchCookie = cookieName => {
  let name = cookieName + "=";
  let allCookieArray = document.cookie.split(";");

  for (let i = 0; i < allCookieArray.length; i++) {
    let temp = allCookieArray[i].trim();

    if (temp.indexOf(name) === 0) {
      return temp.substring(name.length, temp.length);
    }
  }

  return "";
};

const isAuthenticated = () => {
  return fetchCookie("x-auth").length > 0;
};

const validateBook = book => {
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return new Promise((resolve, reject) => {
    if (book.name.length) {
      if (book.surname.length) {
        if (book.email.length) {
          if (re.test(book.email)) {
            return resolve();
          } else {
            reject("Invalid email address!");
          }
        } else {
          reject("Missing Email in the form!");
        }
      } else {
        reject("Missing Last name in the form!");
      }
    } else {
      reject("Missing First name in the form!");
    }
  });
};

const validateArticle = article => {
  return new Promise((resolve, reject) => {
    if (article.title.length) {
      if (article.caption.length) {
        if (article.slug.length) {
          if (article.content.length) {
            return resolve();
          } else {
            reject("Missing content in the form!");
          }
        } else {
          reject("Missing slug in the form!");
        }
      } else {
        reject("Missing caption in the form!");
      }
    } else {
      reject("Missing title in the form!");
    }
  });
};

const socialSection = [
  {
    icon: "/img/weaver-ikonice-01.webp",
    title: "EXPERTISE IN FINANCIAL INDUSTRY",
    text:
      "Until now, we have implemented more than 150 scenarios related to financial industry."
  },
  {
    icon: "/img/weaver-ikonice-02.webp",
    title: "TIME TO MARKET WITH MVP",
    text:
      "Especially for the industries that we are already working with - it takes us much less time because we are already aware of some pain points and we have already defined the needs and developed functionalities to support them."
  },
  {
    icon: "/img/weaver-ikonice-03.webp",
    title: "SIMPLE INTERFACE",
    text:
      "Weaver has a simple interface for setting up and administering the chatbot itself"
  },
  {
    icon: "/img/weaver-ikonice-04.webp",
    title: "ALL CHANNELS",
    text:
      "Weaver can be easily integrated and adapted to any communication channel."
  }
];

export {
  dataReferences,
  dataBenefits,
  features,
  createCookie,
  isAuthenticated,
  validateArticle,
  fetchCookie,
  validateForm,
  requestValidation,
  advantages,
  problemSolutionFinance,
  problemSolutionInsurance,
  problemSolutionTelco,
  problemSolutionFinanceRes,
  problemSolutionInsuranceRes,
  problemSolutionTelcoRes,
  heroPerecentageFinance,
  heroPerecentageInsurance,
  heroPerecentageTelco,
  financeFeature,
  financeInsurance,
  financeTelco,
  faq,
  otherIndustries,
  otherData,
  endCustomer,
  validateBook,
  validateHeroForm,
  whyBenefits,
  APIKEY,
  socialSection
};
